import React, {useEffect} from "react";
import { initFunction } from "../../main";

export const PDC = (props) => {

  const highlightStyle = {
    backgroundColor: 'black',
    color: 'white',
    textAlign: 'center'
  };

  useEffect(() => {
    initFunction();
  }, []);

  return (
    <div>
      <div className="slider-hero featured-carousel-2 owl-carousel">
        <div className="item">
          <div className="work">
            <div className="img d-flex align-items-center justify-content-center" style={{ backgroundImage: "url('img/intro-bg.jpg')" }}>
              <div className="container">
                <div className="text" style={{
                  position: 'relative',
                  marginTop: '40px',
                  color: '#FFF',
                  zIndex: 2
                }}>
                  <h2 style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>Patria Development Center (PDC)</h2>
                  <h2 style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>Facilities</h2>
                  <h2 style={{ borderBottom: '5px solid #C1272D', width: '30%', marginTop : '-20px' }}></h2>
                  <p style={{ visibility: 'hidden' }}>To provide the best solution, from conceptual design into real product applied on site</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="containergray" style={{ backgroundColor: '#BE272D' }}>
        <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', boxShadow: '5px 5px 7px rgba(0,0,0,0.1)' }}>
          <div className="container mt-10">
          <h2 style={{ backgroundColor: 'black', color: 'white', textAlign: 'left', paddingLeft: '30px', paddingRight: '30px', border: '5px solid black', display: 'inline-block' }}>Patria Development Center (PDC) Facilities</h2>
          <p style={{ fontFamily: 'Poppins', color: '#000' }}>PDC is an internal learning center designed to become a center for competency development, certification, leadership and organizational culture to prepare and develop PATRIA's human resources readiness to facing global challenges. To achieve that, PDC organization is equipped with specific section that support all aspects related to development</p>
          </div>
        </div>
        <div className="container mt-15 mb-10" style={{ marginBottom: '-35px' }}>
          <h2 style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center', position: 'relative'}}>Coverage Support PDC</h2>
          <span style={{ marginTop: '-10px', position: 'absolute', left: '50%', transform: 'translateX(-50%)', width: '40%', height: '5px', background: 'radial-gradient(circle at 50% 0%, white, transparent 90%)', borderRadius: '50%' }}></span>
          <div className="row" style={{ marginTop: '80px' }}>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <div className="card" style={{ width: '250px', height: '250px', margin: '10px auto', backgroundColor: '#BE272D', border: 'white 3px solid' }}>
                <img style={{ height: '100px', width: '100px', position: 'relative', zIndex: '2', backgroundColor: '#BE272D', marginTop: '-57px', marginLeft: '35px' }} src="img/pdc/Generalist.svg" alt="Generalist" />
                <div className="card-body">
                  <h4 style={{ fontWeight: 'bold', color: '#fff' }}>Generalist</h4><br/>
                  <ul style={{listStyleType: 'disc', fontFamily: 'Poppins', color: '#fff', fontWeight: 'bold', fontSize: '13' }}>
                    <li style={{marginLeft: '15px', marginBottom: '5px' }}>Plant & Site Superior</li>
                    <li style={{marginLeft: '15px', marginBottom: '5px' }}>Development Prog.</li>
                    <li style={{marginLeft: '15px', marginBottom: '5px' }}>Group Development Prog</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <div className="card" style={{ width: '250px', height: '250px', margin: '10px auto', backgroundColor: '#BE272D', border: 'white 3px solid' }}>
                <img style={{ height: '100px', width: '100px', position: 'relative', zIndex: '2', backgroundColor: '#BE272D', marginTop: '-57px', marginLeft: '35px' }} src="img/pdc/Specialist.svg" alt="Generalist" />
                <div className="card-body">
                  <h4 style={{ fontWeight: 'bold', color: '#fff' }}>Specialist</h4><br/>
                  <ul style={{listStyleType: 'disc', fontFamily: 'Poppins', color: '#fff', fontWeight: 'bold', fontSize: '13' }}>
                    <li style={{marginLeft: '15px', marginBottom: '5px' }}>Engineer Development Prog.</li>
                    <li style={{marginLeft: '15px', marginBottom: '5px' }}>Fabricator Development Prog.</li>
                    <li style={{marginLeft: '15px', marginBottom: '5px' }}>Sales Development Prog.</li>
                    <li style={{marginLeft: '15px', marginBottom: '5px' }}>Technician Development Prog.</li>
                    <li style={{marginLeft: '15px', marginBottom: '5px' }}>Machinist Development Prog</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <div className="card" style={{ width: '250px', height: '250px', margin: '10px auto', backgroundColor: '#BE272D', border: 'white 3px solid' }}>
                <img style={{ height: '100px', width: '100px', position: 'relative', zIndex: '2', backgroundColor: '#BE272D', marginTop: '-57px', marginLeft: '35px' }} src="img/pdc/Technical non-Specialist.svg" alt="Generalist" />
                <div className="card-body">
                  <h4 style={{ fontWeight: 'bold', color: '#fff' }}>Technical non-Specialist</h4>
                  <ul style={{listStyleType: 'disc', fontFamily: 'Poppins', color: '#fff', fontWeight: 'bold', fontSize: '13' }}>
                    <li style={{marginLeft: '15px', marginBottom: '5px' }}>Operator Development Prog.</li>
                    <li style={{marginLeft: '15px', marginBottom: '5px' }}>Mechanic Development Prog.</li>
                    <li style={{marginLeft: '15px', marginBottom: '5px' }}>QA Development Prog.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <div className="card" style={{ width: '250px', height: '250px', margin: '10px auto', backgroundColor: '#BE272D', border: 'white 3px solid' }}>
                <img style={{ height: '100px', width: '100px', position: 'relative', zIndex: '2', backgroundColor: '#BE272D', marginTop: '-57px', marginLeft: '35px' }} src="img/pdc/External & Outsource.svg" alt="Generalist" />
                <div className="card-body">
                  <h4 style={{ fontWeight: 'bold', color: '#fff' }}>External & Outsource</h4><br/>
                  <ul style={{listStyleType: 'disc', fontFamily: 'Poppins', color: '#fff', fontWeight: 'bold', fontSize: '13' }}>
                    <li style={{marginLeft: '15px', marginBottom: '5px' }}>Vendor Development Prog.</li>
                    <li style={{marginLeft: '15px', marginBottom: '5px' }}>University Partnership</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', boxShadow: '5px 5px 7px rgba(0,0,0,0.1)' }}>
          <div className="container mt-10 mb-10">
            <div style={{ position: 'relative', textAlign: 'center' }}>
              <img
                src="img/pdc/pdc.png"
                alt=""
                style={{ height: 'auto', maxWidth: '100%', boxShadow: '0 0 0 0 transparent', position: 'relative' }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
