import React, { useState, useEffect } from "react";
import JsonDataEng from "../../data/data-eng.json";
import JsonDataIdn from "../../data/data-idn.json";
import SmoothScroll from "smooth-scroll";
import "./../../App.css";
import { NavBar } from "../../components/layout/navbar";
import { Navigation } from "../../components/layout/navigation";
import { BODDetail, BodDetail } from "../../components/company-profile/bod-detail";
import { Contact } from "../../components/layout/contact";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const BODDetailPage = () => {
  var sessionLanguage = "";
  if(sessionStorage.getItem('language') == undefined){
    sessionLanguage = "ENG";
    sessionStorage.setItem('language', 'ENG');
  }else{
    sessionLanguage = sessionStorage.getItem('language');
    sessionStorage.setItem('language', sessionStorage.getItem('language'));
  }
  
  const [language, setLanguage] = useState(sessionLanguage);
  const handleClick = (language) => {
    setLanguage(language);
    sessionStorage.setItem('language', language);
  }

  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    if (language === "IDN") {
      setLandingPageData(JsonDataIdn);
    } else {
      setLandingPageData(JsonDataEng);
    }
  }, [language]);  

  return (
    <div>
    <NavBar handleClick={handleClick}/>
    <Navigation data1={landingPageData.Navigation} data2={landingPageData.SubNavigation} data3={landingPageData.SubDropdown} data4={landingPageData.SubNavigationProduct}/>
    <BodDetail/>
    <Contact data={landingPageData.Contact} />
  </div>
  );
};

export default BODDetailPage;
