import React, { useState, useEffect, useRef } from "react";
import { Navigation } from "../components/layout/navigation";
import { Media } from "../components/layout/media";
import JsonDataEng from "../data/data-eng.json";
import JsonDataIdn from "../data/data-idn.json";
import SmoothScroll from "smooth-scroll";
import "../App.css";
import { NavBar } from "../components/layout/navbar";
import { initFunction } from "../main";
import { Contact } from "../components/layout/contact";
import { useLocation, useNavigate } from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import {Helmet} from "react-helmet";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return `${day} ${monthNames[monthIndex]} ${year}`;
}

const News = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  let Id = null;
  let IdCategoryMediaRoom = null;

  if (location.state) {
    Id = location.state.Id;
    IdCategoryMediaRoom = location.state.IdCategoryMediaRoom;
  } else {
    Id = params.get("title");
    IdCategoryMediaRoom = params.get("category");
    // console.log(Id);
  }

  var sessionLanguage = "";
  if(sessionStorage.getItem('language') == undefined){
    sessionLanguage = "ENG";
    sessionStorage.setItem('language', 'ENG');
  }else{
    sessionLanguage = sessionStorage.getItem('language');
    sessionStorage.setItem('language', sessionStorage.getItem('language'));
  }
  
  const [language, setLanguage] = useState(sessionLanguage);
  const handleClick = (language) => {
    setLanguage(language);
    sessionStorage.setItem('language', language);
  }

  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    if (language === "IDN") {
      setLandingPageData(JsonDataIdn);
    } else {
      setLandingPageData(JsonDataEng);
    }
  }, [language, Id]);  

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      initFunction();
    }, 50); 

    return () => clearTimeout(timeoutId);
  }, []);

  //untuk memanggil related news 
  const [dataCurrentNews, setDataCurrentNews] = useState([]);
  const [dataRelatedNews, setDataRelatedNews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://satria-apps.patria.co.id/satria-api-man/public/api/website-media-room-list?page=' + currentPage + '&language=' + language + '&id_category_media_room=' + IdCategoryMediaRoom, {
          method: 'GET',
          headers: {
            'Authorization': '527|8MeS4tgdX837vT9MJYHnLV8pAwN3Dqh1TCK7bTWT'
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        
        const dataArray = jsonData.data.data;
        setTotalPages(jsonData.data.last_page);
        setDataRelatedNews(dataArray);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [currentPage, language, IdCategoryMediaRoom]); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://satria-apps.patria.co.id/satria-api-man/public/api/website-media-room-list?language=' + language + '&id=' + Id, {
          method: 'GET',
          headers: {
            'Authorization': '527|8MeS4tgdX837vT9MJYHnLV8pAwN3Dqh1TCK7bTWT'
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        
        const dataArray = jsonData.data.data;
        setDataCurrentNews(dataArray);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [language, Id]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPagination = () => {
    const pages = [];
  
    // Tambahkan tombol "Previous" jika tidak berada di halaman pertama
    if (currentPage > 1) {
      pages.push(
        <li className="page-item-news" key="previous">
          <a className="page-link-news" href="#" onClick={(event) => { event.preventDefault(); handlePageChange(currentPage - 1); }}>&laquo;</a>
        </li>
      );
    }
  
    // Tambahkan halaman-halaman yang sesuai
    for (let page = Math.max(1, currentPage - 1); page <= Math.min(totalPages, currentPage + 1); page++) {
      pages.push(
        <li className={`page-item-news ${currentPage === page ? 'active' : ''}`} key={page}>
          <a className="page-link-news" href="#" onClick={(event) => { event.preventDefault(); handlePageChange(page); }}>{page}</a>
        </li>
      );
    }
  
    // Tambahkan tanda "..." jika ada halaman setelah halaman terakhir yang ditampilkan
    if (currentPage < totalPages - 1) {
      pages.push(
        <li className="page-item-news disabled" key="ellipsis">
          <a className="page-link-news" href="#" onClick={(event) => { event.preventDefault(); }}>&hellip;</a>
        </li>
      );
    }
  
    // Tambahkan tombol "Next" jika tidak berada di halaman terakhir
    if (currentPage < totalPages) {
      pages.push(
        <li className="page-item-news" key="next">
          <a className="page-link-news" href="#" onClick={(event) => { event.preventDefault(); handlePageChange(currentPage + 1); }}>&raquo;</a>
        </li>
      );
    }
  
    return pages;
  };

  //update visitors
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://satria-apps.patria.co.id/satria-api-man/public/api/website-media-room-update-viewers?id=' + Id, {
          method: 'GET',
          headers: {
            'Authorization': '527|8MeS4tgdX837vT9MJYHnLV8pAwN3Dqh1TCK7bTWT'
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        console.log("Success update viewers")
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [Id]);
  
  //untuk copy to clipboard
  const [copied, setCopied] = useState(false);

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch(err => console.error('Failed to copy:', err));
  };

  //untuk ckeditor
  const editorRef = useRef();

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.ui.getEditableElement().parentElement.insertBefore(
        editorRef.current.ui.view.toolbar.element,
        editorRef.current.ui.getEditableElement()
      );
    }
  }, []);

  return (
    <div>
      <Helmet>
        <meta property="og:site_name" content="Tribunnews.com"/>
        <meta property="og:type" content="article" />
        <meta property="og:title" content="BMKG: Gempa M 4,7 Guncang Bengkulu Pagi Ini - Tribunnews.com"/>
        <meta property="og:image" content="https://asset-2.tstatic.net/tribunnews/foto/bank/images/bmkg-gempa-m-47-guncang-bengkulu-pagi-ini.jpg" />
        <meta property="og:image:secure_url" content="https://asset-2.tstatic.net/tribunnews/foto/bank/images/bmkg-gempa-m-47-guncang-bengkulu-pagi-ini.jpg" />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="1400" />
        <meta property="og:image:height" content="768" />
        <meta property="og:description" content="Gempa berkekuatan magnitudo 4,7 mengguncang Bengkulu pada Jumat (24/5/2024) pagi ini, pukul 06.25 WIB." />
        <meta property="og:url" content="https://www.tribunnews.com/regional/2024/05/24/bmkg-gempa-m-47-guncang-bengkulu-pagi-ini" />
      </Helmet>
      <NavBar handleClick={handleClick}/>
      <Navigation data1={landingPageData.Navigation} data2={landingPageData.SubNavigation} data3={landingPageData.SubDropdown} data4={landingPageData.SubNavigationProduct}/>
      <div className="slider-hero featured-carousel-2 owl-carousel">
        <div className="item">
          <div className="work">
            <div className="img d-flex align-items-center justify-content-center" style={{ backgroundImage: "url('img/intro-bg.jpg')" }}>
              <div className="container">
                <div className="text" style={{
                  position: 'relative',
                  marginTop: '40px',
                  color: '#FFF',
                  zIndex: 2
                }}>
                  <h2 style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>Media Room</h2>
                  <h2 style={{ borderBottom: '5px solid #C1272D', width: '30%', marginTop : '-20px' }}></h2>
                  <p style={{ visibility: 'hidden' }}>To provide the best solution, from conceptual design into real product applied on site</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="containergray mb-15" style={{ backgroundColor: '#F2F2F2' }}>
        <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', boxShadow: '5px 5px 7px rgba(0,0,0,0.1)' }}>
          <div className="container mt-10">
            <h2 style={{ fontWeight: 'bold', color: '#000', borderLeft: '5px solid #C1272D', paddingLeft: '15px' }}>Media Room</h2>
          </div>
        </div>
        <div className="container mt-15" style={{ marginBottom: '-60px' }}>
          {dataCurrentNews && dataCurrentNews.length > 0 ? (
            dataCurrentNews.map((d, i) => {
              return (
                <div key={`${d.id}`}>
                  <h2 style={{ borderBottom: '30px solid #C1272D'}}></h2>
                  <h2 style={{ fontWeight: 'normal', color: '#F2F2F2', marginTop: '-47px', fontSize: '20', textAlign: 'center' }}>{d.name_category}</h2>
                  <img style={{ width: "100%", boxShadow: '0 0 0 0 transparent', margin: 'auto' }} src={`https://satria-apps.patria.co.id/satria-apps/public/images_media_room/image_header/${d.image}`} className="img-responsive" alt="" />
                  <h2 style={{ color: '#C1272D', textAlign: 'center', padding: '20px 10px 0px 10px'}}>{d.title}</h2>
                  <h2 style={{ fontWeight: 'normal', color: '#333333', textAlign: 'center', fontSize: '15px', padding: '0px 21px 0px 21px' }}>{d.summary}</h2>
                  <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img style={{ width: "20px", height: "20px", boxShadow: '0 0 0 0 transparent', marginRight: '10px'}} src="img/news/book.png" className="img-responsive" alt="" />
                      <h4 style={{ fontWeight: 'bold', color: '#1A1A1A', fontSize: '15' }}>{d.reading_duration} Menit membaca</h4>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <h4 style={{ fontWeight: 'bold', color: '#1A1A1A', fontSize: '15px', marginRight: '10px' }}>Bagikan</h4>
                      <a href={`https://www.facebook.com/sharer.php?u=https://mch.my.id/news?title=${d.link_news}%26category=${d.link_category_news}`} target="_blank" rel="noopener noreferrer"><i className="social-icons-news fa fa-facebook" style={{ width: '28px', padding: '3px 5px 3px 7px' }}></i></a>
                      <a href={`https://api.whatsapp.com/send?phone=&text=*${d.title.replace('&', '%26')}*%0A%0A${d.summary.replace('&', '%26')}%0A%0ABaca selengkapnya di link https://mch.my.id/news?title=${d.link_news}%26category=${d.link_category_news}`} target="_blank" rel="noopener noreferrer"><i className="social-icons-news fa fa-whatsapp"></i></a>
                      <a href="#" onClick={(event) => { event.preventDefault(); handleCopyToClipboard(`https://mch.my.id/news?title=${d.link_news}&category=${d.link_category_news}`); }}><i className="social-icons-news fa fa-link"></i></a>
                      {copied && <span style={{ marginLeft: '5px', color: 'green' }}>Copied!</span>}
                    </div>
                  </div>
                  <CKEditor
                    onReady={ editor => {
                        console.log( 'Editor is ready to use!', editor );
                        editor.ui.getEditableElement().parentElement.insertBefore(
                            editor.ui.view.toolbar.element,
                            editor.ui.getEditableElement()
                        );
                        editorRef.current = editor;
                    }}
                    onError={ ( error, { willEditorRestart } ) => {
                        // Handle error
                    }}
                    onChange={ ( event ) => console.log( event ) }
                    editor={ DecoupledEditor }
                    config={{
                      toolbar: false
                    }}                  
                    disabled={true}
                    data={d.description}
                  />
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginBottom: '50px' }}>
                    <h4 style={{ fontWeight: 'bold', color: '#808080', fontSize: '15', marginRight: '15px' }}>Posted : {formatDate(d.date_news)}</h4>
                    <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#1A1A1A', padding: '0 10 0 10', borderStartStartRadius: '10px' , borderStartEndRadius: '10px', borderEndStartRadius: '10px', borderEndEndRadius: '10px' }}>
                    <img style={{ width: "20px", height: "20px", boxShadow: '0 0 0 0 transparent', marginRight: '10px'}} src="img/news/eye.png" className="img-responsive" alt="" />
                      <h4 style={{ fontWeight: 'bold', color: '#F2F2F2', fontSize: '15' }}>Views : {d.count_visitors}</h4>
                    </div>
                  </div>
                </div>
              );
            })
          ) : 
            <div style={{ margin: '0 auto', width: '250px', height: '250px', marginTop: '20px'}}>
              <img src="img/news/not-found.png" alt="No related news" style={{ width: '200px', height: '200px'}}/>
              <h3 style={{ fontWeight: 'bold', color: '#C1272D', fontSize: '20', marginTop: '0px' }}>Media Room Not Found</h3>
            </div>
          }
          <h1 style={{ color: '#1A1A1A', marginBottom: '30px' }}>Related News</h1>
          <div className="row">
          {dataRelatedNews && dataRelatedNews.length > 0 ? (
            dataRelatedNews
              .filter(item => item.id !== Id)
              .map((item, index) => (
                <div
                  className="col-md-3 col-sm-6 mb-10"
                  style={{
                    borderLeft:
                      window.innerWidth < 768
                        ? 'none'
                        : window.innerWidth >= 768 && window.innerWidth < 992
                        ? index % 2 === 0
                          ? 'none'
                          : '1px solid grey'
                        : window.innerWidth >= 992
                        ? index % 4 === 0
                          ? 'none'
                          : '1px solid grey'
                        : '',
                  }}
                  key={item.id}
                >
                  <img
                    style={{
                      width: '329px',
                      height: '173px',
                      boxShadow: '0 0 0 0 transparent',
                      margin: 'auto',
                      borderStartStartRadius: '10px',
                      borderStartEndRadius: '10px',
                      borderEndStartRadius: '10px',
                      borderEndEndRadius: '10px',
                    }}
                    src={`https://satria-apps.patria.co.id/satria-apps/public/images_media_room/image_header/${item.image}`}
                    className="img-responsive"
                    alt=""
                  />
                  <h4
                    style={{
                      fontWeight: 'bold',
                      color: '#808080',
                      fontSize: '15',
                      marginRight: '15px',
                    }}
                  >
                    {formatDate(item.date_news)}
                  </h4>
                  <h4 style={{ color: '#C1272D' }}>{item.name_category}</h4>
                  <div style={{ height: '100px' }}>
                    <h2 style={{ color: '#333333', fontSize: '23' }}>
                      {item.title && item.title.length > 40
                        ? `${item.title.slice(0, 40)}...`
                        : item.title}
                    </h2>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '-20px',
                      marginBottom: '-20px',
                    }}
                  >
                    <img
                      style={{
                        width: '15px',
                        height: '15px',
                        boxShadow: '0 0 0 0 transparent',
                        marginRight: '10px',
                      }}
                      src="img/news/book.png"
                      className="img-responsive"
                      alt=""
                    />
                    <h4
                      style={{
                        fontWeight: 'bold',
                        color: '#808080',
                        fontSize: '12',
                      }}
                    >
                      {item.reading_duration} Menit membaca
                    </h4>
                  </div>
                  <div style={{ height: '180px' }}>
                    <p
                      style={{
                        fontFamily: 'Poppins',
                        color: '#808080',
                        marginTop: '20px',
                        fontSize: '15',
                      }}
                    >
                      {item.summary && item.summary.length > 200
                        ? `${item.summary.slice(0, 200)}...`
                        : item.summary}
                    </p>
                  </div>
                  <a
                    href="#"
                    style={{
                      fontFamily: 'Poppins',
                      color: '#0071BC',
                      fontSize: '15',
                      fontWeight: 'bold',
                    }}
                    onClick={(event) => {
                      event.preventDefault();
                      navigate('/news', {
                        state: { Id: item.id, IdCategoryMediaRoom: item.id_category_media_room },
                      });
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                  >
                    Read More
                  </a>
                </div>
              ))
            ) : 
              <div style={{ margin: '0 auto', width: '250px', height: '250px', marginTop: '20px'}}>
                <img src="img/news/not-found.png" alt="No related news" style={{ width: '200px', height: '200px'}}/>
                <h3 style={{ fontWeight: 'bold', color: '#C1272D', fontSize: '20', marginTop: '0px' }}>Media Room Not Found</h3>
              </div>
            }
          </div>
          <nav className="mb-15" aria-label="Page navigation example">
            <ul className="pagination-news">
              {renderPagination()}
            </ul>
          </nav>
        </div>
      </div>
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default News;
