import React, { useState, useEffect } from "react";
import { Navigation } from "../components/layout/navigation";
import { Media } from "../components/layout/media";
import JsonDataEng from "../data/data-eng.json";
import JsonDataIdn from "../data/data-idn.json";
import SmoothScroll from "smooth-scroll";
import "../App.css";
import { NavBar } from "../components/layout/navbar";
import { initFunction } from "../main";
import { Contact } from "../components/layout/contact";
import { useLocation, useNavigate } from "react-router-dom";
import Select from 'react-select';
import {isMobile} from 'react-device-detect';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return `${day} ${monthNames[monthIndex]} ${year}`;
}

const NewsHome = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { IdCategoryMediaRoom } = location.state || { IdCategoryMediaRoom: 'all' };

  var sessionLanguage = "";
  if(sessionStorage.getItem('language') == undefined){
    sessionLanguage = "ENG";
    sessionStorage.setItem('language', 'ENG');
  }else{
    sessionLanguage = sessionStorage.getItem('language');
    sessionStorage.setItem('language', sessionStorage.getItem('language'));
  }
  
  const [language, setLanguage] = useState(sessionLanguage);
  const handleClick = (language) => {
    setLanguage(language);
    sessionStorage.setItem('language', language);
  }

  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    if (language === "IDN") {
      setLandingPageData(JsonDataIdn);
    } else {
      setLandingPageData(JsonDataEng);
    }
  }, [language, IdCategoryMediaRoom]);  

  //untuk memanggil related news 
  const [dataRelatedNews, setDataRelatedNews] = useState([]);
  const [dataHeaderNews, setDataHeaderNews] = useState([]);
  const [dataCategoryNews, setDataCategoryNews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [newsYear, setnewsYear] = useState("");
  const [newsOrder, setnewsOrder] = useState("");
  const [newsOrder2, setnewsOrder2] = useState("");
  const [newsSearch, setNewsSearch] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://satria-apps.patria.co.id/satria-api-man/public/api/website-media-room-list?page=' + currentPage + '&language=' + language + '&id_category_media_room=' + IdCategoryMediaRoom + '&year=' + newsYear + '&orderBy=' + newsOrder + '&orderBy2=' + newsOrder2 + '&search=' + newsSearch + '&pagination=8', {
          method: 'GET',
          headers: {
            'Authorization': '527|8MeS4tgdX837vT9MJYHnLV8pAwN3Dqh1TCK7bTWT'
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        const dataArray = jsonData.data.data;
        setTotalPages(jsonData.data.last_page);
        setDataRelatedNews(dataArray);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [currentPage, language, IdCategoryMediaRoom, newsYear, newsOrder, newsOrder2, newsSearch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://satria-apps.patria.co.id/satria-api-man/public/api/website-media-room-list?page=' + currentPage + '&language=' + language + '&id_category_media_room=all', {
          method: 'GET',
          headers: {
            'Authorization': '527|8MeS4tgdX837vT9MJYHnLV8pAwN3Dqh1TCK7bTWT'
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        const dataArray = jsonData.data.data;
        setDataHeaderNews(dataArray);
        setTimeout(() => {
          initFunction();
        }, 50);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [language]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://satria-apps.patria.co.id/satria-api-man/public/api/website-media-room-category-list', {
          method: 'GET',
          headers: {
            'Authorization': '527|8MeS4tgdX837vT9MJYHnLV8pAwN3Dqh1TCK7bTWT'
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        const dataArray = jsonData.data;
        setDataCategoryNews(dataArray);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPagination = () => {
    const pages = [];
  
    // Tambahkan tombol "Previous" jika tidak berada di halaman pertama
    if (currentPage > 1) {
      pages.push(
        <li className="page-item-news" key="previous">
          <a className="page-link-news" href="#" onClick={(event) => { event.preventDefault(); handlePageChange(currentPage - 1); }}>&laquo;</a>
        </li>
      );
    }
  
    // Tambahkan halaman-halaman yang sesuai
    for (let page = Math.max(1, currentPage - 1); page <= Math.min(totalPages, currentPage + 1); page++) {
      pages.push(
        <li className={`page-item-news ${currentPage === page ? 'active' : ''}`} key={page}>
          <a className="page-link-news" href="#" onClick={(event) => { event.preventDefault(); handlePageChange(page); }}>{page}</a>
        </li>
      );
    }
  
    // Tambahkan tanda "..." jika ada halaman setelah halaman terakhir yang ditampilkan
    if (currentPage < totalPages - 1) {
      pages.push(
        <li className="page-item-news disabled" key="ellipsis">
          <a className="page-link-news" href="#" onClick={(event) => { event.preventDefault(); }}>&hellip;</a>
        </li>
      );
    }
  
    // Tambahkan tombol "Next" jika tidak berada di halaman terakhir
    if (currentPage < totalPages) {
      pages.push(
        <li className="page-item-news" key="next">
          <a className="page-link-news" href="#" onClick={(event) => { event.preventDefault(); handlePageChange(currentPage + 1); }}>&raquo;</a>
        </li>
      );
    }
  
    return pages;
  };
  
  const handleFilterClick = () => {
    setCurrentPage(1); // Mengatur ulang nilai currentPage menjadi 1

    setNewsSearch(""); // Mengatur ulang value search menjadi kosong
  };

  //untuk react-select tahun 
  const [openYear, setOpenYear] = useState(false);
  const handleSelectClickYear = () => {
    setOpenYear(!openYear);
  };

  const optionsYear = [
    { value: 'all', label: 'Semua' },
    { value: '2024', label: '2024' },
    { value: '2023', label: '2023' },
    { value: '2022', label: '2022' }
  ]

  const handleSelectChangeYear = (selected) => {
    if(selected.value == "all"){
      setnewsYear("");
    }else{
      setnewsYear(selected.value);
    }
    setCurrentPage(1);
  };

  const selectStylesYear = (openYear) => ({
    option: (provided, state) => ({
      ...provided,
      backgroundImage: state.isSelected ? 'linear-gradient(90deg, #C1272D 1%, #FEF3C7 1%)' : '#F2F2F2',
      backgroundColor: state.isSelected ? '#FEF3C7' : '#F2F2F2',
      color: state.isSelected ? 'black' : 'black',
      '&:hover': {
        backgroundImage: 'linear-gradient(90deg, #C1272D 1%, #FEF3C7 1%)',
        color: 'black',
      },
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: '#F2F2F2',
      color: 'black',
      width: '150px',
      '@media (max-width: 992px)': {
        width: '100%'
      },
      height: '20px',
      borderRadius: '4px',
      fontFamily: 'Poppins',
      cursor: 'pointer',
      border: '1 #E5E7EB !important',
      boxShadow: 'none',
      marginBottom: '12px'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#F2F2F2',
      width: '150px',
      '@media (max-width: 992px)': {
        width: '100%'
      },
      position: 'absolute',
      top: '45',
      left: '0',
      right: '0',
      zIndex: 99,
      maxHeight: openYear ? '300px' : '0px',
      overflow: "hidden",
      opacity: openYear ? 1 : 0,
      transition: "all 1s ease-in-out",
      visibility: openYear ? "visible" : "hidden"
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: (defaultStyles) => ({
      ...defaultStyles,
      transform: 'scale(1.5) translateX(-4px)'
    })    
  });

  //untuk react-select order 
  const [openOrder, setOpenOrder] = useState(false);
  const handleSelectClickOrder = () => {
    setOpenOrder(!openOrder);
  };

  const optionsOrder = [
    { value: 'Newest', label: 'Newest' },
    { value: 'Oldest', label: 'Oldest' },
    { value: 'Popular', label: 'Popular' }
  ]

  const handleSelectChangeOrder = (selected) => {
    if(selected.value == "Newest"){
      setnewsOrder("date_news");
      setnewsOrder2("Desc");
    }else if(selected.value == "Oldest"){
      setnewsOrder("date_news");
      setnewsOrder2("Asc");
    }else if(selected.value == "Popular"){
      setnewsOrder("count_visitors");
      setnewsOrder2("Desc");
    }
  };

  const selectStylesOrder = (openOrder) => ({
    option: (provided, state) => ({
      ...provided,
      backgroundImage: state.isSelected ? 'linear-gradient(90deg, #C1272D 1%, #FEF3C7 1%)' : '#F2F2F2',
      backgroundColor: state.isSelected ? '#FEF3C7' : '#F2F2F2',
      color: state.isSelected ? 'black' : 'black',
      '&:hover': {
        backgroundImage: 'linear-gradient(90deg, #C1272D 1%, #FEF3C7 1%)',
        color: 'black',
      },
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: '#F2F2F2',
      color: 'black',
      width: '150px',
      '@media (max-width: 992px)': {
        width: '100%'
      },
      height: '20px',
      borderRadius: '4px',
      fontFamily: 'Poppins',
      cursor: 'pointer',
      border: '1 #E5E7EB !important',
      boxShadow: 'none',
      marginBottom: '12px'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#F2F2F2',
      width: '150px',
      '@media (max-width: 992px)': {
        width: '100%'
      },
      position: 'absolute',
      top: '45',
      left: '0',
      right: '0',
      zIndex: 99,
      maxHeight: openOrder ? '300px' : '0px',
      overflow: "hidden",
      opacity: openOrder ? 1 : 0,
      transition: "all 1s ease-in-out",
      visibility: openOrder ? "visible" : "hidden"
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: (defaultStyles) => ({
      ...defaultStyles,
      transform: 'scale(1.5) translateX(-4px)'
    })    
  });

  return (
    <div>
      <NavBar handleClick={handleClick}/>
      <Navigation data1={landingPageData.Navigation} data2={landingPageData.SubNavigation} data3={landingPageData.SubDropdown} data4={landingPageData.SubNavigationProduct}/>
      <div className="slider-hero featured-carousel-2 owl-carousel">
        <div className="item">
          <div className="work">
            <div className="img d-flex align-items-center justify-content-center" style={{ backgroundImage: "url('img/intro-bg.jpg')" }}>
              <div className="container">
                <div className="text" style={{
                  position: 'relative',
                  marginTop: '40px',
                  color: '#FFF',
                  zIndex: 2
                }}>
                  <h2 style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>Media Room</h2>
                  <h2 style={{ borderBottom: '5px solid #C1272D', width: '30%', marginTop : '-20px' }}></h2>
                  <p style={{ visibility: 'hidden' }}>To provide the best solution, from conceptual design into real product applied on site</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="containergray mb-15" style={{ backgroundColor: '#F2F2F2' }}>
        <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', boxShadow: '5px 5px 7px rgba(0,0,0,0.1)' }}>
          <div className="container mt-10">
            <h2 style={{ fontWeight: 'bold', color: '#000', borderLeft: '5px solid #C1272D', paddingLeft: '15px' }}>Media Room</h2>
          </div>
        </div>
        <div className="container mt-15" style={{ marginBottom: '-60px' }}>
          <div className="filter-media-room-scrollbar" style={{ marginBottom: '40px' }}>
            <div className="d-flex align-items-center filter-media-room-scrollbar-container" style={{ borderBottom: '2px solid #C1272D', paddingBottom: '8px' }}>
              <div className="col-12 d-flex align-items-center mb-4 mb-lg-0" style={{ marginRight: '85px' }}>
                <a className={`filter-media-room ${IdCategoryMediaRoom === 'all' ? 'filter-media-room-active' : ''}`} href="#" onClick={(event) => { event.preventDefault(); handleFilterClick(); navigate('/news-home', { state: { IdCategoryMediaRoom: 'all' } }); }} style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ fontWeight: 'normal', color: '#333333', fontSize: '20px', textAlign: 'left'}}>All</div>
                </a>
              </div>
              {dataCategoryNews ? (
                dataCategoryNews.map((h, t) => {
                  return (
                    <div key={t} className="col-12 d-flex align-items-center mb-4 mb-lg-0" style={{ marginRight: '85px' }}>
                      <a className={`filter-media-room ${IdCategoryMediaRoom === h.id ? 'filter-media-room-active' : ''}`} href="#" onClick={(event) => { event.preventDefault(); handleFilterClick(); navigate('/news-home', { state: { IdCategoryMediaRoom: h.id } }); }} style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ fontWeight: 'normal', color: '#333333', fontSize: '20px', textAlign: 'left'}}>{h.name}</div>
                      </a>
                    </div>
                  );
                })
              ) : "loading"}
            </div>
            <div style={{ borderBottom: '2px solid #C1272D', marginTop: '-3px' }}></div>
          </div>
          <div className="slider-hero mb-13">
            <div className="featured-carousel-3 owl-carousel">
              {dataHeaderNews.map((item) => (
                <div className="item" key={item.id}>
                  <div className="work-2">
                    <div className="img d-flex align-items-center justify-content-center" style={{ backgroundImage: `url('https://satria-apps.patria.co.id/satria-apps/public/images_media_room/image_header/${item.image}')` }}>
                      <div className="container">
                        <div className="text" style={{
                          position: 'relative',
                          marginTop: '25px',
                          color: '#FFF',
                          zIndex: 2
                        }}>
                          <div className="carousel-header-media-room" style={{ paddingLeft: '100'}}>
                            <h2 style={{ fontWeight: 'bold', fontSize: '15', textTransform: 'capitalize' }}>{formatDate(item.date_news)}</h2>
                            <h2 style={{ fontWeight: 'normal', fontSize: '20', textTransform: 'capitalize' }}>{item.name_category}</h2>
                            <h2 style={{ fontWeight: 'bold', width: '30%', fontSize: '30', textTransform: 'capitalize' }}>{item.title && item.title.length > 40 ? `${item.title.slice(0, 40)}...` : item.title}</h2>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <button type="button" className="btn btn-secondary" style={{ fontFamily: 'Poppins', marginRight: '10px'}} onClick={(event) => { event.preventDefault(); navigate('/news', { state: { Id: item.id, IdCategoryMediaRoom: item.id_category_media_room } }); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>Read More</button>
                              <div style={{ display: 'flex', alignItems: 'center', marginTop: '-20px', marginBottom: '-20px' }}>
                                <img style={{ width: "25px", height: "25px", marginRight: '10px', filter: 'invert(100%)' }} src="img/news/book.png" className="img-responsive" alt="" />
                                <h4 style={{ fontWeight: 'bold', color: '#FFFFFF', fontSize: '15' }}>{item.reading_duration} Menit membaca</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="news-home-filter">
            <h3 style={{ fontWeight: 'bold', color: '#C1272D', fontSize: '20', marginTop: '0px' }}>Semua Artikel</h3>
            <div className="news-home-filter-2">
              <a style={{ fontFamily: 'Poppins', fontWeight: 'normal', color: '#333333', fontSize: '15', textAlign: 'left', marginTop: '-10px', marginRight: '10px' }}>Tahun : </a>
              <div onTouchStart={isMobile ? handleSelectClickYear : undefined} onClick={!isMobile ? handleSelectClickYear : undefined} style={{ marginRight: '10px' }}>
                <Select
                  defaultValue={optionsYear[0]}
                  onChange={handleSelectChangeYear}
                  options={optionsYear}
                  isSearchable={false}
                  onBlur={() => setOpenYear(false)}
                  menuIsOpen
                  styles={selectStylesYear(openYear)}
                />
              </div>
              <a style={{ fontFamily: 'Poppins', fontWeight: 'normal', color: '#333333', fontSize: '15', textAlign: 'left', marginTop: '-10px', marginRight: '10px' }}>Urutkan Berdasarkan : </a>
              <div onTouchStart={isMobile ? handleSelectClickOrder : undefined} onClick={!isMobile ? handleSelectClickOrder : undefined} style={{ marginRight: '10px' }}>
                <Select
                  defaultValue={optionsOrder[0]}
                  onChange={handleSelectChangeOrder}
                  options={optionsOrder}
                  isSearchable={false}
                  onBlur={() => setOpenOrder(false)}
                  menuIsOpen
                  styles={selectStylesOrder(openOrder)}
                />
              </div>
              <input 
                className="form-control news-home-filter-3" 
                type="text" 
                value={newsSearch}
                placeholder="Search" 
                style={{ 
                  width: '170px', 
                  height: '38px', 
                  marginTop: '-12px',
                  backgroundSize: '20px 20px',
                  backgroundImage: 'url("img/news/magnifying.png")',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'right center'
                }} 
                onChange={(e) => setNewsSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
          {dataRelatedNews.length <= 0 ? (
            <div style={{ margin: '0 auto', width: '250px', height: '250px', marginTop: '20px'}}>
              <img src="img/news/not-found.png" alt="No related news" style={{ width: '200px', height: '200px'}}/>
              <h3 style={{ fontWeight: 'bold', color: '#C1272D', fontSize: '20', marginTop: '0px' }}>Media Room Not Found</h3>
            </div>
          ) : (
            dataRelatedNews.map((item, index) => (
              <div className="col-md-3 col-sm-6 mb-10" style={{ borderLeft: window.innerWidth < 768 ? 'none' : (window.innerWidth >= 768 && window.innerWidth < 992 ? (index % 2 === 0 ? 'none' : '1px solid grey') : (window.innerWidth >= 992 ? (index % 4 === 0 ? 'none' : '1px solid grey') : '')) }} key={item.id}>
                <img style={{ width: "329px", height: "173px", boxShadow: '0 0 0 0 transparent', margin: 'auto', borderStartStartRadius: '10px' , borderStartEndRadius: '10px', borderEndStartRadius: '10px', borderEndEndRadius: '10px'}} src={`https://satria-apps.patria.co.id/satria-apps/public/images_media_room/image_header/${item.image}`} className="img-responsive" alt="" />
                <h4 style={{ fontWeight: 'bold', color: '#808080', fontSize: '15', marginRight: '15px' }}>{formatDate(item.date_news)}</h4>
                <h4 style={{ color: '#C1272D' }}>{item.name_category}</h4>
                <div style={{ height: '100px'}}>
                  <h2 style={{ color: '#333333', fontSize: '23' }}>{item.title && item.title.length > 40 ? `${item.title.slice(0, 40)}...` : item.title}</h2>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '-20px', marginBottom: '-20px' }}>
                  <img style={{ width: "15px", height: "15px", boxShadow: '0 0 0 0 transparent', marginRight: '10px'}} src="img/news/book.png" className="img-responsive" alt="" />
                  <h4 style={{ fontWeight: 'bold', color: '#808080', fontSize: '12' }}>{item.reading_duration} Menit membaca</h4>
                </div>
                <div style={{ height: '180px'}}>
                  <p style={{ fontFamily: 'Poppins', color: '#808080', marginTop: '20px', fontSize: '15'}}>{item.summary && item.summary.length > 200 ? `${item.summary.slice(0, 200)}...` : item.summary}</p>
                </div>
                <a href="#" style={{ fontFamily: 'Poppins', color: '#0071BC', fontSize: '15', fontWeight: 'bold'}} onClick={(event) => { event.preventDefault(); navigate('/news', { state: { Id: item.id, IdCategoryMediaRoom: item.id_category_media_room } }); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>Read More</a>
              </div>
            ))
          )}
          </div>
          <nav className="mb-15" aria-label="Page navigation example">
            <ul className="pagination-news">
              {renderPagination()}
            </ul>
          </nav>
        </div>
      </div>
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default NewsHome;
