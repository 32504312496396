import React, { useState, useEffect } from "react";
import { Navigation } from "../components/layout/navigation";
import { Header } from "../components/layout/header";
import { About } from "../components/layout/about";
import { Info } from "../components/layout/info";
import { Afco } from "../components/layout/afco";
import { Category } from "../components/layout/category";
import { Contact } from "../components/layout/contact";
import { Media } from "../components/layout/media";
import JsonDataEng from "../data/data-eng.json";
import JsonDataIdn from "../data/data-idn.json";
import SmoothScroll from "smooth-scroll";
import "../App.css";
import { NavBar } from "../components/layout/navbar";
import { initFunction } from "../main";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Home = () => {
  useEffect(() => {
    return () => {
      scroll.destroy();
    };
  }, []);

  var sessionLanguage = "";
  if(sessionStorage.getItem('language') == undefined){
    sessionLanguage = "ENG";
    sessionStorage.setItem('language', 'ENG');
  }else{
    sessionLanguage = sessionStorage.getItem('language');
    sessionStorage.setItem('language', sessionStorage.getItem('language'));
  }
  
  const [language, setLanguage] = useState(sessionLanguage);
  const handleClick = (language) => {
    setLanguage(language);
    sessionStorage.setItem('language', language);
  }

  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    if (language === "IDN") {
      setLandingPageData(JsonDataIdn);
    } else {
      setLandingPageData(JsonDataEng);
    }
  }, [language]);  

  //untuk mengambil data media room dari api
  const [dataCurrentNews, setDataCurrentNews] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://satria-apps.patria.co.id/satria-api-man/public/api/website-media-room-list?language=' + language + '&id_category_media_room=all', {
          method: 'GET',
          headers: {
            'Authorization': '527|8MeS4tgdX837vT9MJYHnLV8pAwN3Dqh1TCK7bTWT'
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        
        const dataArray = jsonData.data.data;
        setDataCurrentNews(dataArray);
        initFunction();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [language]);

  return (
    <div>
      <NavBar handleClick={handleClick}/>
      <Navigation data1={landingPageData.Navigation} data2={landingPageData.SubNavigation} data3={landingPageData.SubDropdown} data4={landingPageData.SubNavigationProduct}/>
      <Header data={landingPageData.Header} />
      <Category data={landingPageData.Category} />
      <About data={landingPageData.About} />
      <Info data={landingPageData.Info} />
      <Media data={dataCurrentNews}/>
      <Afco data={landingPageData.Afco} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default Home;
