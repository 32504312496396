import React from "react";

export const About = (props) => {
  return (
    <div className="container mb-10 mt-10">
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="text" style={{ borderLeft: '5px solid #C1272D', paddingLeft: '15px' }}><br/><br/><br/>
            <h2 style={{ fontWeight: 'bold', color: '#C1272D', marginBottom: '0' }}>WELCOME TO</h2>
            <h3 style={{ color: '#000', marginTop: '0' }}>PT UNITED TRACTORS PANDU ENGINEERING</h3>
            <p style={{ fontFamily: 'Poppins', color: '#000' }}>{props.data ? props.data.paragraph : "loading..."}</p><br/><br/><br/>
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div style={{ padding: '56.25% 0 0 0', position: 'relative', display: 'block', width: '100%' }}>
            <iframe style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }} src="https://www.youtube.com/embed/rsiAaakbZGQ?si=4_9Z7fjdzjqGDWRV" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};
