import React from "react";
import { useNavigate,Link, useLocation } from "react-router-dom";

export const Media = (props) => {
  const navigate = useNavigate();
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
    ];
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
  
    return `${day} ${monthNames[monthIndex]} ${year}`;
  }
  
  return (
    <div id="media">
      <div className="container"><br/><br/>
      <div style={{ margintop: '-100px !important' }}>
        <h2 style={{ color: '#000', textAlign: 'center', margin: '0 auto' }}>Media Room</h2>
        <div style={{ borderBottom: '2px solid #C1272D', width: '150px', color: '#000', textAlign: 'center', margin: '0 auto' }}></div>
      </div><br/><br/>
        <div className="regular slider">
        {props.data ? props.data.map((d, i) => (
          <div key={`${d.id}-${i}`}>
            <img style={{ width: "329px", height: "173px", boxShadow: '0 0 0 0 transparent', margin: 'auto', borderStartStartRadius: '10px' , borderStartEndRadius: '10px', borderEndStartRadius: '10px', borderEndEndRadius: '10px'}} src={`https://satria-apps.patria.co.id/satria-apps/public/images_media_room/image_header/${d.image}`} className="img-responsive" alt="" />
            <h4 style={{ fontWeight: 'bold', color: '#808080', fontSize: '15', marginRight: '15px' }}>{formatDate(d.date_news)}</h4>
            <h4 style={{ color: '#C1272D' }}>{d.name_category}</h4>
            <div style={{ height: '100px'}}>
              <h2 style={{ color: '#333333', fontSize: '23' }}>{d.title && d.title.length > 40 ? `${d.title.slice(0, 40)}...` : d.title}</h2>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '-20px', marginBottom: '-20px' }}>
              <img style={{ width: "15px", height: "15px", boxShadow: '0 0 0 0 transparent', marginRight: '10px'}} src="img/news/book.png" className="img-responsive" alt="" />
              <h4 style={{ fontWeight: 'bold', color: '#808080', fontSize: '12' }}>{d.reading_duration} Menit membaca</h4>
            </div>
            <div style={{ height: '160px'}}>
              <p style={{ fontFamily: 'Poppins', color: '#808080', marginTop: '20px', fontSize: '15'}}>{d.summary && d.summary.length > 150 ? `${d.summary.slice(0, 150)}...` : d.summary}</p>
            </div>
            <a href="#" style={{ fontFamily: 'Poppins', color: '#0071BC', fontSize: '15', fontWeight: 'bold'}} onClick={(event) => { event.preventDefault(); navigate('/news', { state: { Id: d.id, IdCategoryMediaRoom: d.id_category_media_room } }); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>Read More</a>
          </div>
          )) : "loading"}
        </div>
      </div>
    </div>
  );
};
