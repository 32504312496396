import React, {useEffect, useState} from "react";
import { initFunction } from "../../main";
import FSLightbox from "fslightbox-react";

export const Manf = (props) => {

  useEffect(() => {
    initFunction();
  }, []);
  
  const [toggler, setToggler] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  return (
    <div>
      <div className="slider-hero featured-carousel-2 owl-carousel">
        <div className="item">
          <div className="work">
            <div className="img d-flex align-items-center justify-content-center" style={{ backgroundImage: "url('img/intro-bg.jpg')" }}>
              <div className="container">
                <div className="text" style={{
                  position: 'relative',
                  marginTop: '40px',
                  color: '#FFF',
                  zIndex: 2
                }}>
                  <h2 style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>Manufacturing Facilities</h2>
                  <h2 style={{ borderBottom: '5px solid #C1272D', width: '30%', marginTop : '-20px' }}></h2>
                  <p style={{ visibility: 'hidden' }}>To provide the best solution, from conceptual design into real product applied on site</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="containergray">
        <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', boxShadow: '5px 5px 7px rgba(0,0,0,0.1)' }}>
          <div className="container mt-10">
            <h3 style={{ backgroundColor: 'black', color: 'white', textAlign: 'left', paddingLeft: '30px', paddingRight: '30px', border: '5px solid black', display: 'inline-block', marginBottom: '20px', fontSize: '30' }}>Manufacturing Facilities</h3>
            <div className="row">
              {props.data
                ? props.data.map((d, i) => (
                  <div key={`${i}`} className="col-md-6 col-sm-12 mb-10">
                    <a href="#" onClick={(e) => { e.preventDefault(); setSelectedImage(d.img); setToggler(!toggler); }}>
                      <img style={{ width: "600px", height: '300px', boxShadow: '0 0 0 0 transparent' }} src={d.img} className="img-responsive mb-5" alt={d.img} />
                      <h2 style={{ fontWeight: 'bold', textTransform: 'capitalize', fontSize: '30' }}>{d.title}</h2>
                    </a>
                  </div>
                ))
              : "loading"}
            </div>
          </div>
        </div>
      </div>
      <FSLightbox
        toggler={toggler}
        sources={selectedImage ? [selectedImage] : []}
      />
    </div>
  );
};
