import React, { useState, useEffect, useRef } from "react";
import JsonDataEng from "../../data/data-eng.json";
import JsonDataIdn from "../../data/data-idn.json";
import SmoothScroll from "smooth-scroll";
import "./../../App.css";
import { NavBar } from "../../components/layout/navbar";
import { Navigation } from "../../components/layout/navigation";
import { Contact } from "../../components/layout/contact";
import { useLocation, useNavigate } from "react-router-dom";
import { initFunction } from "../../main";
import FSLightbox from "fslightbox-react";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const ProductPage = () => {
  var sessionLanguage = "";
  if(sessionStorage.getItem('language') == undefined){
    sessionLanguage = "ENG";
    sessionStorage.setItem('language', 'ENG');
  }else{
    sessionLanguage = sessionStorage.getItem('language');
    sessionStorage.setItem('language', sessionStorage.getItem('language'));
  }
  
  const [toggler, setToggler] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [language, setLanguage] = useState(sessionLanguage);
  const handleClick = (language) => {
    setLanguage(language);
    sessionStorage.setItem('language', language);
  }

  const location = useLocation();
  const { Id } = location.state || { Id: '1' };
  const { menu } = location.state || { menu: 'Unit' };
  const { submenu } = location.state || { submenu: 'Mining' };
  const displayMenu = menu !== 'Unit' ? menu : submenu;

  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    if (language === "IDN") {
      setLandingPageData(JsonDataIdn);
    } else {
      setLandingPageData(JsonDataEng);
    }
  }, [language, Id]);  

  useEffect(() => {
    initFunction();
  }, []);

  const containerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const containerBottom = containerRef.current.offsetTop + containerRef.current.clientHeight;
        const scrollPosition = window.scrollY + window.innerHeight;
        const contactFloat = document.querySelector(".contact-float > div");
  
        if (scrollPosition >= containerBottom) {
          if (contactFloat) {
            contactFloat.style.position = 'absolute';
          }
        } else {
          if (contactFloat) {
            contactFloat.style.position = 'fixed';
          }
        }
      }
    };
  
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  const navigate = useNavigate();
  
  return (
    <div>
      <NavBar handleClick={handleClick}/>
      <Navigation data1={landingPageData.Navigation} data2={landingPageData.SubNavigation} data3={landingPageData.SubDropdown} data4={landingPageData.SubNavigationProduct}/>
      <div className="slider-hero featured-carousel-2 owl-carousel">
        <div className="item">
          <div className="work">
            <div className="img d-flex align-items-center justify-content-center" style={{ backgroundImage: "url('img/intro-bg.jpg')" }}>
              <div className="container">
                <div className="text" style={{
                  position: 'relative',
                  marginTop: '40px',
                  color: '#FFF',
                  zIndex: 2
                }}>
                  <h2 style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{menu}</h2>
                  <h2 style={{ borderBottom: '5px solid #C1272D', width: '30%', marginTop : '-10px' }}></h2>
                  <p style={{ visibility: 'hidden' }}>To provide the best solution, from conceptual design into real product applied on site</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="containergray mb-15" style={{ backgroundColor: '#F9F9F9', position: 'relative' }} ref={containerRef}>
        <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', boxShadow: '5px 5px 7px rgba(0,0,0,0.1)' }}>
          <div className="container mt-10">
            <h2 style={{ fontWeight: 'bold', color: '#000', borderLeft: '5px solid #C1272D', paddingLeft: '15px' }}>{displayMenu}</h2>
          </div>
        </div>
        {menu === 'Unit' && (
          <div className="container mt-15">
            <div className="filter-unit-product-main" style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '2px solid #C1272D', paddingBottom: '2px' }}>
              <a style={{ fontWeight: 'normal', color: '#333333', fontSize: '20', textAlign: 'left', marginTop: '-5px' }}>Filter Unit berdasarkan</a>
              <div className="filter-unit-product-scrollbar">
                <div className="d-flex align-items-center filter-unit-product-scrollbar-container">
                {landingPageData.SubNavigationProduct ? (
                    landingPageData.SubNavigationProduct.map((h, t) => {
                      if (h.category_id == '1') {
                        return (
                          <div key={t} className="col-12 d-flex align-items-center mb-4 mb-lg-0" style={{ margin: '0 15px' }}>
                            <a className={`filter-unit-product ${Id === h.category_product_id ? 'filter-unit-product-active' : ''}`} href="#" onClick={(event) => { event.preventDefault(); navigate(h.href, { state: { Id: h.category_product_id, menu: menu, submenu: h.title } }); }} style={{ display: 'flex', alignItems: 'center' }}>
                              <img style={{ width: '20px', marginRight: '5px' }} src={h.img} alt="Unit" />
                              <div style={{ fontWeight: 'normal', color: '#333333', fontSize: '15px', textAlign: 'left'}}>{h.title}</div>
                            </a>
                          </div>
                        );
                      }
                    })
                  ) : "loading"}
                </div>
              </div>
            </div>
          </div>
        )}
        {menu == 'Unit' && (
          landingPageData.Product ? (
            Object.values(landingPageData.Product.reduce((acc, product) => {
              const key = `${product.prd_pdc_id}-${product.prd_pdc_sub_name}`;
              if (!acc[key]) {
                acc[key] = [];
              }
              acc[key].push(product);
              return acc;
            }, {})).filter(group => group.some(product => product.prd_pdc_id === Id)).map((group, i) => (
              <div key={i} className="container mt-15 text-center" style={{ marginBottom: '-60px' }}>
                <h2 style={{ fontWeight: 'normal', color: '#000', textAlign: 'center', position: 'relative'}}>{group[0].prd_pdc_sub_name}</h2>
                <div style={{ borderBottom: '2px solid #C1272D', width: '200px', color: '#000', textAlign: 'center', margin: '0 auto', marginTop: '-10px' }}></div>
                <div className="product-container mt-15">
                  {group.map((product, index) => (
                    <div key={`${product.prd_name}-${index}`} className="product-item">
                      <div className="overlay"></div>
                      <h3 className="title-text-product">{product.prd_name}</h3>
                      <a href="#" onClick={(e) => { e.preventDefault(); setSelectedImage(product.prd_pic); setToggler(!toggler); }}>
                        <img src={product.prd_pic} alt="" />
                      </a>
                      <h3 className="hover-text">{product.prd_name}</h3>
                      <h5 className="hover2-text">{product.prd_desc}</h5>
                    </div>
                  ))}
                </div>
              </div>
            ))
          ) : "loading"
        )}

        <div className="container mt-15 text-center" style={{ marginBottom: '-60px'}}>
          <div className="row">
            {menu === 'Parts' && landingPageData.Product ? (
              landingPageData.Product.filter(d => d.prd_pdc_id === Id).map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-md-4 col-sm-6" style={{ marginBottom: '200px', height: '200px' }}>
                  <a className="card card-parts hover-card" href="#" onClick={(e) => { e.preventDefault(); setSelectedImage(d.prd_pic); setToggler(!toggler); }} style={{ backgroundColor: '#ffffff', borderRadius: '0px', height: '330px'}}>
                    <img src={d.prd_pic} alt="Kitten" style={{ marginTop: '-10px'}} />
                    <div className="card-body" style={{ marginLeft:'20px'}}>
                      <h4 style={{ fontWeight: 'bold', color: '#1A1A1A', textAlign: 'left' }}>{d.prd_name}</h4><br/>
                      <p style={{ fontFamily: 'Poppins', color: '#000000', fontSize: '14', textAlign: 'left' }}>{d.prd_desc}</p>
                      <p style={{ fontFamily: 'Poppins', color: '#000000', fontSize: '14', textAlign: 'left' }}>{d.prd_desc2}</p>
                    </div>
                    <h2 className="animated-border"></h2>
                  </a>
                </div>
              ))
            ) : null}
            </div>
        </div>

        <div className="container mt-15 text-center">
          <div className="row">
            {menu === 'Services' && landingPageData.Product ? (
              landingPageData.Product.filter(d => d.prd_pdc_id === Id).map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-md-6 col-sm-12 card-services" style={{ marginBottom: '100px', height: '300px' }}>
                  <a className="card hover-card" href="#" onClick={(e) => { e.preventDefault(); setSelectedImage(d.prd_pic); setToggler(!toggler); }} style={{ backgroundColor: '#ffffff', borderRadius: '0px'}}>
                    <img src={d.prd_pic} alt="Kitten" style={{ marginTop: '-10px' }} />
                    <div className="animated-border-2" style={{ borderBottom: '10px solid #ffffff', width: '100%', margin: '0 auto'}}></div>
                    <div className="card-body" style={{ marginLeft:'20px', marginTop: '-100px'}}>
                      <h4 style={{ fontWeight: 'bold', color: '#F2F2F2', textAlign: 'left', fontSize: '35', marginBottom: '50px' }}>{d.prd_name}</h4>
                      <p style={{ fontFamily: 'Poppins', color: '#000000', fontSize: '25', textAlign: 'left' }}>{d.prd_desc}</p>
                     </div>
                  </a>
                </div>
              ))
            ) : null}
          </div>
        </div>

        <div className="container mt-15 text-center" style={{ marginBottom: '-60px'}}>
          <div className="product-reman-container">
            {menu === 'Remanufacture' && landingPageData.Product ? (
              landingPageData.Product.filter(d => d.prd_pdc_id === Id).map((d, i) => (
                <div key={`${d.title}-${i}`} className="product-item" style={{ marginBottom: '400px', height: '200px' }}>
                  <a className="card hover-card-3" href="#" onClick={(e) => { e.preventDefault(); setSelectedImage(d.prd_pic); setToggler(!toggler); }} style={{ backgroundColor: 'rgb(249, 249, 249)', borderRadius: '0px', border: '1px solid rgb(0 0 0 / 0%)'}}>
                    <img src={d.prd_pic} alt="Kitten" style={{ marginTop: '-10px' }} />
                    <div className="card card-animated-3" style={{ backgroundColor: '#ffffff', borderRadius: '0px', width: '270px', height: '230px', marginTop: '-35px', marginLeft: '-10px'}}>
                      <div className="animated-border-2" style={{ borderBottom: '10px solid #ffffff', width: '100%', margin: '0 auto'}}></div>
                      <div className="card-body">
                        <h4 style={{ fontWeight: 'bold', color: '#1A1A1A', textAlign: 'left' }}>{d.prd_name}</h4><br/>
                        <p style={{ fontFamily: 'Poppins', color: '#000000', fontSize: '14', textAlign: 'left' }}>{d.prd_desc}</p>
                      </div>
                    </div>
                  </a>
                </div>
              ))
            ) : null}
          </div>
        </div>
        <div className="contact-float">
          <div style={{ position: 'fixed', bottom: '10px', right: '20px', width: '155px', background: '#FFFFFF', padding: '10px', paddingTop: '1px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', borderRadius: '10px', zIndex: '999' }}>
            <h3>More Information ?</h3>
            <a type="button" className="btn btn-danger" style={{ fontFamily: 'Poppins', backgroundColor: '#C1272D'}} href="https://www.triatra.co.id" target="_blank"><img style={{ marginRight: '2rem', width: '20px' }} src="img/product/contact.png" alt="Unit" />Hit us up!</a>
          </div>
        </div>
      </div>
      <FSLightbox
        toggler={toggler}
        sources={selectedImage ? [selectedImage] : []}
      />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default ProductPage;
