import React, { useState, useEffect } from 'react';
import { Navigation } from '../components/layout/navigation';
import { useSearchParams } from "react-router-dom";
import JsonDataEng from "../data/data-eng.json";
import JsonDataIdn from "../data/data-idn.json";
import { Contact } from '../components/layout/contact';
import { NavBar } from '../components/layout/navbar';
import SmoothScroll from 'smooth-scroll';
import { initFunction } from "../main";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Vcard = () => {
  const [dataVcard, setData] = useState([]);
  const [queryParameters] = useSearchParams();
  const apiUrl = `https://satria.patria.co.id/satria-api-man/public/api/sf-emp-vcard/${queryParameters.get("q")}`;
  const accessToken = '38|rCG1TgCKPV1YMUv5AKP8zi8ukemsvL3L4QMJNDN2';

  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          Authorization: `${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      const safetyData = responseData.data;
      setData(safetyData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  var sessionLanguage = "";
  if(sessionStorage.getItem('language') == undefined){
    sessionLanguage = "ENG";
    sessionStorage.setItem('language', 'ENG');
  }else{
    sessionLanguage = sessionStorage.getItem('language');
    sessionStorage.setItem('language', sessionStorage.getItem('language'));
  }
  
  const [language, setLanguage] = useState(sessionLanguage);
  const handleClick = (language) => {
    setLanguage(language);
    sessionStorage.setItem('language', language);
  }

  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    if (language === "IDN") {
      setLandingPageData(JsonDataIdn);
    } else {
      setLandingPageData(JsonDataEng);
    }
  }, [language]);  

  useEffect(() => {
    initFunction();
    fetchData();
  }, []);

  const saveContact = () => {
    const url = `${window.location.origin}/v-card-save/index.php`;
    const queryParams = {
      action: 'export',
      name: dataVcard[0]?.nama || '',
      phone: dataVcard[0]?.phone || '',
      email: dataVcard[0]?.email || '',
      title: dataVcard[0]?.tittle || '',
      company: dataVcard[0]?.company_name || '',
      addresss: '',
    };
  
    const queryString = Object.keys(queryParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
      .join('&');
  
    const fullUrl = `${url}?${queryString}`;
    console.log(fullUrl);
    
    const link = document.createElement('a');
    link.href = fullUrl;
    link.download = 'contact.vcf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };  

  return (
    <div>
      <NavBar handleClick={handleClick}/>
      <Navigation data1={landingPageData.Navigation} data2={landingPageData.SubNavigation} data3={landingPageData.SubDropdown} data4={landingPageData.SubNavigationProduct}/>
      <div className="slider-hero featured-carousel-2 owl-carousel">
        <div className="item">
          <div className="work">
            <div className="img d-flex align-items-center justify-content-center" style={{ backgroundImage: "url('img/intro-bg.jpg')" }}>
              <div className="container">
                <div className="text" style={{
                  position: 'relative',
                  marginTop: '40px',
                  color: '#FFF',
                  zIndex: 2
                }}>
                  <h2 style={{ fontWeight: 'bold' }}>V Card</h2>
                  <h2 style={{ borderBottom: '5px solid #C1272D', width: '50%' }}></h2>
                  <p style={{ visibility: 'hidden' }}>To provide the best solution, from conceptual design into real product applied on site</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {dataVcard ? dataVcard.map((d, i) => (
      <div key={`${d.name}-${i}`} className="container vcard mt-15 mb-15">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <img 
              style={{ height: '250px', width: '200px' }} 
              src={d.nrp ? 
                    `https://satria.patria.co.id/satria/public/profile/${d.nrp}.png` : 
                    'https://satria.patria.co.id/satria/public/assets/global/img/no-profile.jpg'}
              onError={(e) => { e.target.onerror = null; e.target.src = 'https://satria.patria.co.id/satria/public/assets/global/img/no-profile.jpg'; }}
              alt="photo" 
            />
            <div className="card" style={{ marginTop: '-40px', width: '190px', marginLeft: '5px', backgroundColor: '#e7e7e7' }}>
              <div className="card-body">
                <h6 style={{ fontWeight: 'bold', color: '#333333', textAlign: 'center'}}>{d.nama}</h6>
                <h6 style={{ fontWeight: 'normal', color: '#333333', textAlign: 'center'}}>{d.tittle}</h6>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 mt-10">
            <div id="vcard" className="text">
              <h2 className="mb-10" style={{ fontWeight: 'bold', color: '#D00'}}>Contact</h2>
              <div className="d-flex align-items-center justify-content-left mb-7">
                <div style={{ minWidth: '30px', maxWidth: '30px', marginRight: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0118 0z" />
                    <circle cx="12" cy="10" r="3" />
                  </svg>
                </div>
                <div className="ms-3" style={{ borderLeft: '3px solid gray', paddingLeft: '10px' }}>
                  <h4 style={{ color: '#333333' }}>{d.department}</h4>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-left mb-7">
                <div style={{ minWidth: '30px', maxWidth: '30px', marginRight: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M22 16.92v3a2 2 0 01-2.18 2 19.79 19.79 0 01-8.63-3.07 19.5 19.5 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 014.11 2h3a2 2 0 012 1.72 12.84 12.84 0 00.7 2.81 2 2 0 01-.45 2.11L8.09 9.91a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45 12.84 12.84 0 002.81.7A2 2 0 0122 16.92z" /></svg>
                </div>
                <div className="ms-3" style={{ borderLeft: '3px solid gray', paddingLeft: '10px' }}>
                  <h4 style={{ color: '#333333' }}>{d.phone}</h4>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-left mb-7">
                <div style={{ minWidth: '30px', maxWidth: '30px', marginRight: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                  <path d="M22 6l-10 7L2 6" /></svg>
                </div>
                <div className="ms-3" style={{ borderLeft: '3px solid gray', paddingLeft: '10px' }}>
                  <h4 style={{ color: '#333333' }}>{d.email}</h4>
                </div>
              </div>
              <button type="button" className="btn btn-danger mt-10" style={{ fontFamily: 'Poppins', margin: '10px auto', display: 'block', width: '300px', fontWeight: 'bold', fontSize: '20px' }} onClick={saveContact}>Save Contact</button>
            </div>
          </div>
        </div>
      </div>
      )) : "loading"}
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default Vcard;