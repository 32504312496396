import React, {useEffect} from "react";
import { initFunction } from "../../main";

export const Overview = (props) => {

  const highlightStyle = {
    color: '#C1272D',
    fontWeight: 'bold'
  };

  useEffect(() => {
    initFunction();
  }, []);

  return (
    <div>
      <div className="slider-hero featured-carousel-2 owl-carousel">
        <div className="item">
          <div className="work">
            <div className="img d-flex align-items-center justify-content-center" style={{ backgroundImage: "url('img/intro-bg.jpg')" }}>
              <div className="container">
                <div className="text" style={{
                  position: 'relative',
                  marginTop: '40px',
                  color: '#FFF',
                  zIndex: 2
                }}>
                  <h2 style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>Company</h2>
                  <h2 style={{ fontWeight: 'bold', marginTop: '-20px', textTransform: 'capitalize' }}>Overview</h2>
                  <h2 style={{ borderBottom: '5px solid #C1272D', width: '30%', marginTop : '-20px' }}></h2>
                  <p style={{ visibility: 'hidden' }}>To provide the best solution, from conceptual design into real product applied on site</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="containergray mb-15">
        <div className="container" style={{ marginTop: "30px" }}>
          <h2 style={{ fontWeight: 'bold', color: '#000', borderLeft: '5px solid #C1272D', paddingLeft: '15px' }}>Overview</h2>
          <div style={{ textAlign: 'center', marginBottom: '30px' }}>
            <img
              src="img/head_image.png"
              alt=""
              style={{ maxWidth: '20%', display: 'block', margin: '0 auto', boxShadow: '0 0 0 0 transparent' }}
            />
          </div>
          <div className="about-text">
            <p style={{ fontFamily: 'Poppins', color: '#000' }}>{props.data ? props.data.overview1 : "loading"}</p>
            <p style={{ fontFamily: 'Poppins', color: '#000' }}>{props.data ? props.data.overview2 : "loading"}</p>
          </div>
        </div>
        <div style={{ backgroundColor: '#F2F2F2', border: '1px solid #ccc', boxShadow: '5px 5px 7px rgba(0,0,0,0.1)'}}>
          <div className="container mt-10" style={{ marginBottom: '-35px' }}>
            <div className="col-md-12  section-title" style={{ marginBottom: '15px' }}>
              <h2 style={{ fontWeight: 'bold', color: '#000', textAlign: 'center', position: 'relative'}}>Vision</h2>
              <span style={{ marginTop: '-20px', position: 'absolute', left: '50%', transform: 'translateX(-50%)', width: '40%', height: '5px', background: 'radial-gradient(circle at 50% 0%, #C1272D, transparent 90%)', borderRadius: '50%' }}></span>
              <ul>
                <li style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px', fontFamily: 'Poppins', color: '#000'}}><span style={{ marginRight: '8px'}}>•</span>To be the best company that provides engineering & logistic solutions in heavy equipment, maritime, and energy industry</li>
              </ul>
            </div>
            <div className="col-md-12  section-title">
              <h2 style={{ fontWeight: 'bold', color: '#000', textAlign: 'center', position: 'relative'}}>Mission</h2>
              <span style={{ marginTop: '-20px', position: 'absolute', left: '50%', transform: 'translateX(-50%)', width: '40%', height: '5px', background: 'radial-gradient(circle at 50% 0%, #C1272D, transparent 90%)', borderRadius: '50%' }}></span>
              <ul>
                <li style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px', fontFamily: 'Poppins', color: '#000'}}><span style={{ marginRight: '8px'}}>•</span>Prosper with the nation</li>
                <li style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px', fontFamily: 'Poppins', color: '#000'}}><span style={{ marginRight: '8px'}}>•</span>Excel in engineering, research and development to provied sustainable value to our stakeholders</li>
                <li style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px', fontFamily: 'Poppins', color: '#000'}}><span style={{ marginRight: '8px'}}>•</span>Create a place for Patrian to grow and give the best performance</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container" style={{ marginTop: "30px", marginBottom: '100px' }}>
          <div className="col-md-12  section-title">
            <h2 style={{ fontWeight: 'bold', color: '#000', borderLeft: '5px solid #C1272D', paddingLeft: '15px' }}>PATRIA VALUE</h2>
            <div style={{ textAlign: 'center', marginBottom: '30px' }}>
              <img
                src="img/lapatria.png"
                alt=""
                style={{ maxWidth: '20%', display: 'block', margin: '0 auto', boxShadow: '0 0 0 0 transparent' }}
              />
            </div>
            <p style={{ fontFamily: 'Poppins', color: '#000' }}>‘’{props.data ? props.data.lapatria1 : "loading"} <span style={highlightStyle}>{props.data ? props.data.lapatria2 : "loading"}</span> {props.data ? props.data.lapatria3 : "loading"} <span style={highlightStyle}>{props.data ? props.data.lapatria4 : "loading"}</span>, 
            {props.data ? props.data.lapatria5 : "loading"} <span style={highlightStyle}>{props.data ? props.data.lapatria6 : "loading"}</span> {props.data ? props.data.lapatria7 : "loading"} <span style={highlightStyle}>{props.data ? props.data.lapatria8 : "loading"}</span> {props.data ? props.data.lapatria9 : "loading"} <span style={highlightStyle}>{props.data ? props.data.lapatria10 : "loading"}</span>’’</p>
            <div style={{ textAlign: 'center', marginTop: '100px', marginBottom: '-40px' }}>
              <img
                src="img/patriavalue.png"
                alt=""
                style={{ width: '100%', display: 'block', margin: '0 auto', boxShadow: '0 0 0 0 transparent' }}
              />
            </div>
            <div style={{ textAlign: 'center', marginTop: '100px', marginBottom: '-100px' }}>
              <img
                src="img/patriavalue2.png"
                alt=""
                style={{ width: '70%', display: 'block', margin: '0 auto', boxShadow: '0 0 0 0 transparent' }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
