import React from "react";
import { useNavigate,Link, useLocation, json } from "react-router-dom";

export const Info = (props) => {
  const navigate = useNavigate();

  return (
    <div style={{ backgroundColor: '#F2F2F2', border: '1px solid #ccc', width: '100%'}}>
      <div className="container text-center">
        <br/><br/>
        <h4 className="mt-10" style={{ color: '#4D4D4D', fontWeight: 'normal' }}>Our Products</h4>
        <h2 className="mb-15" style={{ color: '#C1272D', fontWeight: 'normal' }}><span style={{ color: '#1A1A1A'}}>Customized</span> Products<span style={{ color: '#1A1A1A'}}>, Based on</span> Your Needs</h2>
        <div className="row">
          {props.data ? props.data.map((d, i) => (
            <div key={`${i}`} className="col-md-3 col-sm-6">
              <a className="card card-info text-left" style={{ maxWidth: '500px', height: '390px', margin: '10px auto', borderRadius:'0', padding:'10px', backgroundImage: `url('${d.coverimg}')`, backgroundSize: 'cover' }} href="#" onClick={(event) => { event.preventDefault(); navigate(d.href, { state: { Id: d.category, menu: d.name, submenu: 'Mining' } }); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>
                <div className="card-body">
                  <img className="image-info mt-10" src={d.img} height="60" alt={d.name}></img><br /><br />
                  <h3 style={{ fontWeight: 'bold', color: '#1A1A1A', marginBottom: '0', textTransform: 'uppercase' }}>{d.name}</h3><br />
                  <p className="mt-8" style={{ fontFamily: 'Poppins', color: '#666666', fontSize: '12', margin: '0 auto' }}>{d.job}</p> 
                </div>
                <div style={{ bottom: '0', textAlign: 'center', paddingLeft: '10px', paddingRight: '10px'}}>
                  <h1 className="border-bottom-info" style={{ borderBottom: '2px solid #666666'}}></h1>
                  <h5 className="mt-7" style={{ color: '#1A1A1A', fontSize: '10', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>Read More</span> 
                    <div className="card-info">
                      <img className="arrow-info" src="img/product/arrow-black.png" height="8" alt="Foto" style={{ marginLeft: '5px', fontSize:'20' }}/>
                      <img className="arrow-info-hover" src="img/product/arrow-white.png" height="15" alt="Foto Hover" style={{ marginLeft: '5px', fontSize:'20', display: 'none'}}/>
                    </div>
                  </h5>
                </div>
              </a>
            </div>
          )) : "loading"}
        </div>
        <h4 className="mt-15 mb-15" style={{ color: '#C1272D', fontWeight: 'normal' }}><span style={{ color: '#1A1A1A'}}>The</span> Best Manufacture<span style={{ color: '#1A1A1A'}}> And</span> Company Services</h4>
      </div>
    </div>
      
  );
};
