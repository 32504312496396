import React, {useEffect, useState} from "react";
import { useNavigate,Link, useLocation } from "react-router-dom";
import { initFunction } from "../../main";
import Select from 'react-select';
import FSLightbox from "fslightbox-react";
import {isMobile} from 'react-device-detect';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/navigation';
// import required modules
import { EffectCards, Navigation, Pagination } from 'swiper/modules';

export const RemanFacilities = (props) => {

  const options = props.data1
    ? [...props.data1.map((d) => ({ value: d.id, label: d.title }))]
    : [];
  
  const [selectedOption, setSelectedOption] = useState();
  const [selectedValue, setSelectedValue] = useState();

  const options2 = props.data2
    ? [...props.data2
      .filter(d => d.idremandropdown1 == (selectedOption ? selectedOption.value : options[0].value))
      .map((d) => ({ value: d.id, label: d.title }))
    ]
    : [];
  
  const handleSelectChange = (selected) => {
    setSelectedValue(null);
    setSelectedOption(selected);
    setCurrentTime(new Date());
  };
    
  const [currentTime, setCurrentTime] = useState(new Date());
  const handleSelectClickLocation = () => {
    setOpenLocation(!openLocation);
  };

  const handleSelectClick = () => {
    if(isMobile){
      setOpen(!open);
    }else{
      setOpen(open);
    }
  };

  const [openLocation, setOpenLocation] = useState(false);
  const selectStylesLocation = (openLocation) => ({
    option: (provided, state) => ({
      ...provided,
      backgroundImage: state.isSelected ? 'linear-gradient(90deg, #C1272D 1%, white 1%)' : '#1A1A1A',
      backgroundColor: state.isSelected ? 'white' : '#1A1A1A',
      color: state.isSelected ? '#1A1A1A' : 'white',
      '&:hover': {
        backgroundImage: 'linear-gradient(90deg, #C1272D 1%, white 1%)',
        color: '#1A1A1A',
      },
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: '#1A1A1A',
      color: 'white',
      width: '320px',
      '@media (max-width: 600px)': {
        width: '90%',
        fontSize: '12px',
      },
      height: '50px',
      borderRadius: '4px',
      fontFamily: 'Poppins',
      cursor: 'pointer',
      border: '0 !important',
      boxShadow: 'none',
      marginBottom: '12px'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#1A1A1A',
      width: '320px',
      '@media (max-width: 600px)': {
        width: '90%',
        fontSize: '12px',
      },
      position: 'absolute',
      top: '45',
      left: '0',
      right: '0',
      zIndex: 99,
      maxHeight: openLocation ? '300px' : '0px',
      overflow: "hidden",
      opacity: openLocation ? 1 : 0,
      transition: "all 1s ease-in-out",
      visibility: openLocation ? "visible" : "hidden"
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: (defaultStyles) => ({
      ...defaultStyles,
      transform: 'scale(1.5) translateX(-4px)'
    })    
  });

  const [open, setOpen] = useState(!isMobile);
  const selectStyles = (open) => ({
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#333333' : '#BE272D',
      color: state.isSelected ? 'white' : 'white',
      '&:hover': {
        backgroundColor: '#333333',
        color: 'white',
      },
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: '#BE272D',
      color: 'white',
      width: '250px',
      '@media (max-width: 600px)': {
        width: '130%',
        minWidth: '130px',
        fontSize: '12px',
      },
      height: '50px',
      borderRadius: '4px',
      margin: '13 auto',
      fontFamily: 'Poppins',
      fontWeight: 'bold',
      textAlign: 'center',
      fontSize: '15px',
      cursor: 'pointer',
      border: '0 !important',
      boxShadow: 'none'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#BE272D',
      width: '250px',
      '@media (max-width: 600px)': {
        width: '130%',
        minWidth: '130px',
      },
      '@media (max-width: 767px)': {
        fontSize: '12px',
        height: open ? `330px` : '0px',
      },
      margin: '0 auto',
      position: 'absolute',
      top: '60',
      left: '0',
      right: '0',
      zIndex: 99,
      fontFamily: 'Poppins',
      fontWeight: 'bold',
      fontSize: '15px',
      textAlign: 'center',
      paddingBottom: '70px !important',
      borderBottomRightRadius: '50px',
      height: open ? `400px` : '0px',
      overflow: "hidden",
      opacity: open ? 1 : 0,
      transition: "all 1s ease-in-out",
      visibility: open ? "visible" : "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      overflowY: 'none',
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: (defaultStyles) => ({
      ...defaultStyles,
      transform: 'scale(1.5) translateX(-4px)'
    })    
  });

  useEffect(() => {
    initFunction();
  }, []);

  const [swiper, setSwiper] = useState(null);

  const handleSelectChange2 = (selectedOption) => {
    setSelectedValue(selectedOption.value);
  };
  
  const [toggler, setToggler] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  return (
    <div style={{ backgroundColor: '#F2F2F2' }}>
      <div className="slider-hero featured-carousel-2 owl-carousel">
        <div className="item">
          <div className="work">
            <div className="img d-flex align-items-center justify-content-center" style={{ backgroundImage: "url('img/intro-bg.jpg')" }}>
              <div className="container">
                <div className="text" style={{
                  position: 'relative',
                  marginTop: '40px',
                  color: '#FFF',
                  zIndex: 2
                }}>
                  <h2 style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>Remanufacturing Facilities</h2>
                  <h2 style={{ borderBottom: '5px solid #C1272D', width: '30%', marginTop : '-20px' }}></h2>
                  <p style={{ visibility: 'hidden' }}>To provide the best solution, from conceptual design into real product applied on site</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="bod" className="containergray" style={{ backgroundColor: '#F2F2F2' }}>
        <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', boxShadow: '5px 5px 7px rgba(0,0,0,0.1)', marginBottom: '50px' }}>
          <div className="container mt-10">
            <h2 style={{ fontWeight: 'bold', color: '#000', borderLeft: '5px solid #C1272D', paddingLeft: '15px' }}>Remanufacturing Facilities</h2>
          </div>
        </div>
        <div className="container reman-facilities">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div onTouchStart={isMobile ? handleSelectClickLocation : undefined} onClick={!isMobile ? handleSelectClickLocation : undefined}>
              {options.length > 0 ? (
                <Select
                  defaultValue={options[0]}
                  onChange={handleSelectChange}
                  options={options}
                  isSearchable={false}
                  onBlur={() => setOpenLocation(false)}
                  menuIsOpen
                  styles={selectStylesLocation(openLocation)}
                />
              ) : ( <></>
              )}
            </div>
            <div className="reman-facilities-select-fasilities" onTouchStart={isMobile ? handleSelectClick : undefined} onClick={!isMobile ? handleSelectClick : undefined} style={{ marginTop: '-13px' }}>
              {options2.length > 0 ? (
                <Select
                  closeMenuOnSelect={false}
                  defaultValue={options2[0]}
                  placeholder={<div className="reman-facilities-select-fasilities-placeholder" style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: '25px', color: 'white' }}>Facilities</div>}
                  options={options2}
                  isSearchable={false}
                  onBlur={() => {
                    if (isMobile) {
                      setOpen(false);
                    } else {
                      setOpen(true);
                    }
                  }}
                  menuIsOpen
                  styles={selectStyles(open)}
                  key={`select-${currentTime}`}
                  onChange={handleSelectChange2}
                />
              ) : ( <></>
              )}
            </div>
          </div>
          <div style={{ marginLeft: '30px' }}>
            <Swiper
              key={`swiper-${options2.length}-${selectedValue}`}
              effect={'cards'}
              grabCursor={true}
              pagination={{
                clickable: true,
                renderBullet: function (index, className) {
                  let imgArray = [];
                  if (props.data3) {
                    props.data3.forEach((d) => {
                      if (
                        (selectedValue ? d.idremandropdown2 === selectedValue : d.idremandropdown2 === options2[0].value)
                      ) {
                        imgArray.push(d.img);
                      }
                    });
                  }
              
                  const img = imgArray[index];
              
                  return img ? `<img src="${img}" class="${className}" alt="Thumbnail ${index + 1}" />` : '';
                },
              }}                             
              navigation={{ clickable: true }}
              modules={[EffectCards, Navigation, Pagination]}
              className="mySwiper"
              onSwiper={setSwiper}
              style={{ '--swiper-navigation-color': '#C1272D' }}
            >
              {props.data3 ? (
                props.data3.map((d, i) => (
                  (selectedValue ? d.idremandropdown2 === selectedValue : d.idremandropdown2 === options2[0].value) ? (
                    <SwiperSlide
                      key={`${i}-${selectedValue}`}
                      style={{ backgroundImage: `url('${d.img}')`, backgroundSize: 'cover' }}
                      onClick={() => {
                        setSelectedImage(d.img);
                        setToggler(!toggler);
                      }}
                    ></SwiperSlide>
                  ) : null
                ))
              ) : "loading"}
            </Swiper>
          </div>
          <h2 style={{ borderBottom: '70px solid #1A1A1A', width: '100%', marginTop : '10px', boxShadow: '0px -50px 40px -10px #1A1A1A', borderRadius: '10px', marginBottom: '50px' }}></h2>
        </div>
        <FSLightbox
          toggler={toggler}
          sources={selectedImage ? [selectedImage] : []}
        />
      </div>
    </div>
  );
};
