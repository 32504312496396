import React, { useState, useEffect } from "react";
import JsonDataEng from "../../data/data-eng.json";
import JsonDataIdn from "../../data/data-idn.json";
import SmoothScroll from "smooth-scroll";
import "./../../App.css";
import { NavBar } from "../../components/layout/navbar";
import { Navigation } from "../../components/layout/navigation";
import { Contact } from "../../components/layout/contact";
import { useLocation } from "react-router-dom";
import { initFunction } from "../../main";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const AfterSalesPage = () => {
  var sessionLanguage = "";
  if(sessionStorage.getItem('language') == undefined){
    sessionLanguage = "ENG";
    sessionStorage.setItem('language', 'ENG');
  }else{
    sessionLanguage = sessionStorage.getItem('language');
    sessionStorage.setItem('language', sessionStorage.getItem('language'));
  }
  
  const [language, setLanguage] = useState(sessionLanguage);
  const handleClick = (language) => {
    setLanguage(language);
    sessionStorage.setItem('language', language);
  }

  const location = useLocation();
  const { Id } = location.state || { Id: 'Spare Parts' };

  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    if (language === "IDN") {
      setLandingPageData(JsonDataIdn);
    } else {
      setLandingPageData(JsonDataEng);
    }
  }, [language, Id]);  

  useEffect(() => {
    initFunction();
  }, []);

  return (
    <div>
      <NavBar handleClick={handleClick}/>
      <Navigation data1={landingPageData.Navigation} data2={landingPageData.SubNavigation} data3={landingPageData.SubDropdown} data4={landingPageData.SubNavigationProduct}/>
      <div className="slider-hero featured-carousel-2 owl-carousel">
        <div className="item">
          <div className="work">
            <div className="img d-flex align-items-center justify-content-center" style={{ backgroundImage: "url('img/intro-bg.jpg')" }}>
              <div className="container">
                <div className="text" style={{
                  position: 'relative',
                  marginTop: '40px',
                  color: '#FFF',
                  zIndex: 2
                }}>
                  <h2 style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{Id}</h2>
                  <h2 style={{ borderBottom: '5px solid #C1272D', width: '30%', marginTop : '-20px' }}></h2>
                  <p style={{ visibility: 'hidden' }}>To provide the best solution, from conceptual design into real product applied on site</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="containergray mb-15" style={{ backgroundColor: '#F2F2F2' }}>
        <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', boxShadow: '5px 5px 7px rgba(0,0,0,0.1)' }}>
          <div className="container mt-10">
            <h2 style={{ fontWeight: 'bold', color: '#000', borderLeft: '5px solid #C1272D', paddingLeft: '15px' }}>{Id}</h2>
          </div>
        </div>
        <div className="container mt-15" style={{ marginBottom: '-60px' }}>
        {landingPageData.AfterSales ? (
          landingPageData.AfterSales.map((d, i) => {
            if (d.category === Id) {
              if (d.type == "list"){
                return (
                  <div key={`${d.title}-${i}`} >
                    <img style={{ width: "100%", boxShadow: '0 0 0 0 transparent', marginBottom: '30px' }} src={d.img} className="img-responsive" alt="" />
                    <h2 style={{ fontWeight: 'bold', color: '#D00', borderLeft: '5px solid #C1272D', paddingLeft: '10px' }}>{d.title}</h2>
                    <p style={{ fontFamily: 'Poppins', color: '#000', marginTop: '20px', marginBottom: '60px' }}>{d.description}</p>
                  </div>
                );
              } else if (d.type == "grid"){
                return (
                  <div key={`${d.title}-${i}`} className="col-6 col-md-6 col-sm-12" style={{padding: '0px'}}>
                    <img style={{ width: "95%", boxShadow: '0 0 0 0 transparent', marginBottom: '30px' }} src={d.img} className="img-responsive" alt="" />
                    <h2 style={{ fontWeight: 'bold', color: '#D00', borderLeft: '5px solid #C1272D', paddingLeft: '10px' }}>{d.title}</h2>
                    <p style={{ fontFamily: 'Poppins', color: '#000', marginTop: '20px', marginBottom: '60px' }}>{d.description}</p>
                  </div>
                );
              }
            }
            return null;
          })
        ) : "loading"}
        </div>
      </div>
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default AfterSalesPage;
