import React, { useState, useRef, useEffect } from 'react';
import { useNavigate,Link, useLocation, json } from "react-router-dom";

export const Navigation = (navigation) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isCurrentRoute = (path, title) => {
    if (path === "#") {
      return navigation.data2 ? navigation.data2.some((d) => d.title === title && ( location.pathname === d.href || location.pathname === d.href2 ||location.pathname === d.href3 ||location.pathname === d.href4 ||location.pathname === d.href5 )) : "loading";
    } else if( title == "Facilities" || title == "Fasilitas"){
      return navigation.data1 ? navigation.data1.some((d) => d.title === title && ( location.pathname === d.href || location.pathname === d.href2 ||location.pathname === d.href3 ||location.pathname === d.href4 ||location.pathname === d.href5 )) : "loading";
    }else {
      return location.pathname === path;
    }
  }; 

  const [isClicked, setIsClicked] = useState(false);
  const dropdownRef = useRef(null);

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsClicked(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top" style={{marginTop: '35px',apacity: '0.7'}}>
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="#" onClick={(event) => { event.preventDefault(); navigate('/'); window.scrollTo({ top: 0, behavior: 'smooth' }); }} to="/" >
            <img src={"img/head_image.png"} alt="..." width={'200'} className="team-img" />
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
          {navigation.data1 ? (
            navigation.data1.map((d, i) => {
              if (d.submenu == 'false') {
                if (d.targetBlank == 'true') {
                  return (
                    <li key={`${d.title}-${i}`} className={`nav-item ${isCurrentRoute(d.href) ? 'active' : ''}`}>
                      <a className="nav-link" href={d.href} role="button"
                        aria-expanded="false" target="_blank">
                        {d.title}
                      </a>
                  </li>
                  );
                } else {
                  if(d.title != 'Contact' && d.title != 'Kontak'){
                    return (
                      <li key={`${d.title}-${i}`} className={`nav-item ${isCurrentRoute(d.href, d.title) ? 'active' : ''}`}>
                        <a className="nav-link" onClick={(event) => { navigate(d.href); window.scrollTo({ top: 0, behavior: 'smooth' }); }} role="button"
                          aria-expanded="false">
                          {d.title}
                        </a>
                      </li>
                    );
                  }else{
                    return (
                      <li key={`${d.title}-${i}`} className={`nav-item ${isCurrentRoute(d.href, d.title) ? 'active' : ''}`} style={{ display: 'flex', alignItems: 'center' }}>
                        <a className="nav-link" style={{ color: '#C1272D', marginLeft: '5px', display: 'flex', alignItems: 'center' }} onClick={(event) => { navigate(d.href); window.scrollTo({ top: 0, behavior: 'smooth' }); }} role="button" aria-expanded="false">
                          <img className="image-info" src="img/contact-icon.png" height="25" alt={d.name} style={{ marginRight: '5px' }} />
                          {d.title}
                        </a>
                      </li>
                    );
                  }
                }
              } else {
                if (d.title == 'Product' || d.title == 'Produk') {
                  return (
                    <li key={`${d.title}-${i}`} id={d.title} className={`nav-item ${isCurrentRoute(d.href, d.title) ? 'active' : ''} dropdown w-100 w-lg-auto dropdown-fullwidth`}>
                      <a className="nav-link" onClick={(event) => { event.preventDefault(); }} role="button" data-bs-toggle="dropdown-2"
                        aria-expanded="false">
                        {d.title}
                      </a>
                      <div className="dropdown-menu pb-0">
                        <div className="row p-2 p-lg-4 mt-8">
                          <h3 style={{ fontWeight: 'bold', marginLeft: '100px' }}>{d.title} <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#C1272D"><path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z"/></svg></h3>
                          <h3 style={{ borderBottom: '1px solid black', width: '90%', marginLeft: '30px'}}></h3>
                          <div style={{ marginLeft: '10px', width: '95%', marginTop: '50px'}}>
                            {navigation.data2 ? (
                              navigation.data2.map((e, j) => {
                                if (e.title == 'Product' || e.title == 'Produk') {
                                  if (e.submenu == 'Unit') {
                                    return (
                                      <div key={`${j}`} ref={dropdownRef} className={`col-lg-3 col-md-6 col-sm-12 dropdown-2 ${isClicked ? 'clicked' : ''}`}>
                                        <div className="d-flex align-items-center navigation-product" style={{ width: '205px', height: '70px'}}>
                                          <div className="col-12 d-flex align-items-center mb-4 mb-lg-0" onClick={handleClick} style={{ cursor: 'pointer' }}>
                                            <img style={{ marginRight: '2rem', width: '40px' }} src={e.img} alt="Unit" />
                                            <div style={{ marginLeft: '-10px' }}>
                                              <h4 style={{ fontWeight: 'bold', color: '#000', fontSize: '15' }}>{e.submenu}</h4>
                                            </div>
                                            <svg style={{ marginLeft: '20px' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#C1272D"><path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z"/></svg>
                                          </div>
                                        </div>
                                        <div className="dropdown-menu-2" style={{ marginLeft: '30px', width: '80%', borderRadius: '0', backgroundColor: '#F2F2F2', display: isClicked ? 'block' : 'none' }}>
                                          <ul>
                                            {navigation.data4 ? (
                                              navigation.data4.map((h, t) => {
                                                if (h.category_id == e.category) {
                                                  return (
                                                    <li className="navigation-product-2" key={`${t}`} style={{ fontFamily: 'Poppins', height: '30px' }}><a href="#" onClick={(event) => { event.preventDefault(); navigate(h.href, { state: { Id: h.category_product_id, menu: e.submenu, submenu: h.title } }); window.scrollTo({ top: 0, behavior: 'smooth' }); }}><h5 className="navigation-product-2-text" style={{ marginLeft: '15px', textTransform: 'none', fontWeight: 'normal', color: '#000', paddingTop: '5px' }}>{h.title}</h5></a></li>
                                                  );
                                                }
                                              })
                                            ) : "loading"}
                                          </ul>
                                        </div>
                                      </div>
                                    );
                                  }else{
                                    return (
                                      <div key={`${j}`} className="col-lg-3 col-md-6 col-sm-12" style={{ borderLeft: '1px solid grey' }}>
                                        <a className="d-flex align-items-center navigation-product" href="#" style={{ width: '205px', height: '70px'}} onClick={(event) => { event.preventDefault(); navigate(e.href, { state: { Id: e.category, menu: e.submenu } }); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>
                                          <div className="col-12 d-flex align-items-center mb-4 mb-lg-0">
                                            <img style={{ marginRight: '2rem', width: '40px' }} src={e.img} alt="Unit" />
                                            <div style={{ marginLeft: '-10px' }}>
                                              <h4 style={{ fontWeight: 'bold', color: '#000', fontSize: '15' }}>{e.submenu}</h4>
                                            </div>
                                          </div>
                                        </a>
                                      </div>
                                    );
                                  }
                                }
                              })
                            ) : "loading"}
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                }else{
                  return (
                    <li key={`${d.title}-${i}`} id={d.title} className={`nav-item ${isCurrentRoute(d.href, d.title) ? 'active' : ''} dropdown w-100 w-lg-auto dropdown-fullwidth`}>
                      <a className="nav-link" onClick={(event) => { event.preventDefault(); }} role="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        {d.title}
                      </a>
                      <div className=" dropdown-menu pb-0">
                        <div className="row p-2 p-lg-4 mt-8">
                          <h3 style={{ fontWeight: 'bold', marginLeft: '100px' }}>{d.title} <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#C1272D"><path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z"/></svg></h3>
                          <h3 style={{ borderBottom: '1px solid black', width: '90%', marginLeft: '30px'}}></h3>
                          <div className="col-lg-6 col-12 mb-4 mb-lg-0 mt-5">
                            <div className="row p-2 p-lg-4">
                              {navigation.data2 ? (
                                navigation.data2.map((e, j) => {
                                  if (e.title == d.title) {
                                    return (
                                      <div key={`${e.submenu}-${j}`} className="col-lg-6 col-12 mb-4 mb-lg-0">
                                        <h5><a className="submenu-text" href="#"  onClick={(event) => { event.preventDefault(); navigate(e.href, { state: { Id: e.category, menu: e.submenu, IdCategoryMediaRoom: 'all' } }); window.scrollTo({ top: 0, behavior: 'smooth' }); }}><strong>{e.submenu}</strong></a></h5>
                                      </div>
                                    );
                                  }
                                })
                              ) : "loading"}
                            </div>
                          </div>
                          <div className="col-lg-6 col-12 mb-4 mb-lg-0" style={{ borderLeft: (navigation.data3 && navigation.data3.some(e => e.title === d.title)) ? '1px solid #C1272D' : 'none', paddingLeft: '15px' }}>
                            <div className="row p-2 p-lg-4">
                              {navigation.data3 ? (
                                navigation.data3.map((e, j) => {
                                  if (e.title == d.title) {
                                    return (
                                      <a href="#" onClick={(event) => { event.preventDefault(); navigate(e.href, { state: { Id: e.category } }); window.scrollTo({ top: 0, behavior: 'smooth' }); }} key={`${j}`} className="col-12 d-flex align-items-center mb-4 mb-lg-0" style={{ borderBottom: '1px solid #C1272D', width: '90%', paddingBottom: '15px', marginLeft: '10px' }}>
                                        <img style={{ marginRight: '2rem', width: '200px' }} src={e.img} alt={e.title} />
                                        <div style={{ marginLeft: '-10px' }}>
                                          <h4 style={{ fontWeight: 'bold', color: '#000' }}>{e.headerNews}</h4>
                                          <p style={{ fontFamily: 'Poppins', color: '#000', fontSize: '12' }}>{e.description}</p>
                                        </div>
                                      </a>
                                    );
                                  }
                                })
                              ) : "loading"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                }
              }
            })
          ) : "loading"}
          </ul>
        </div>
      </div>
    </nav>
  );
};
