import React, {useEffect} from "react";
import { useNavigate,Link, useLocation } from "react-router-dom";
import { initFunction } from "../../main";

export const BOD = (props) => {
  const navigate = useNavigate();

  const handleMouseEnter = (event) => {
    const card = event.target.closest('.hover-card');
    const div = card.querySelector('div');
  
    div.style.boxShadow = "rgb(0 0 0 / 38%) 0px 0px 50px";
  }
  
  const handleMouseLeave = (event) => {
    const card = event.target.closest('.hover-card');
    const div = card.querySelector('div');
  
    div.style.boxShadow = "0 0 50px rgb(255 255 255 / 6%)";
  }
  
  useEffect(() => {
    initFunction();
  }, []);
  
  return (
    <div>
      <div className="slider-hero featured-carousel-2 owl-carousel">
        <div className="item">
          <div className="work">
            <div className="img d-flex align-items-center justify-content-center" style={{ backgroundImage: "url('img/intro-bg.jpg')" }}>
              <div className="container">
                <div className="text" style={{
                  position: 'relative',
                  marginTop: '40px',
                  color: '#FFF',
                  zIndex: 2
                }}>
                  <h2 style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>Board of Commissioners</h2>
                  <h2 style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>& Directors</h2>
                  <h2 style={{ borderBottom: '5px solid #C1272D', width: '30%', marginTop : '-20px' }}></h2>
                  <p style={{ visibility: 'hidden' }}>To provide the best solution, from conceptual design into real product applied on site</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="bod" className="container mt-10 mb-15" style={{ marginBottom: '-35px' }}>
        <h2 style={{ fontWeight: 'bold', color: '#000', borderLeft: '5px solid #C1272D', paddingLeft: '15px'}}>Board of Commissioners & Directors</h2>
        <div className="mt-15">
          <h2 style={{ fontWeight: 'normal', color: '#000', textAlign: 'center', position: 'relative'}}>Board of Commissioners</h2>
          <div style={{ borderBottom: '2px solid #C1272D', width: '150px', color: '#000', textAlign: 'center', margin: '0 auto', marginTop: '-20px' }}></div>
          <div className="container mt-10" style={{ marginBottom: '-35px', textAlign: 'center' }}>
            <div className="row">
              <div className="col-4 col-md-4 col-sm-12 hover-card" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <div>
                  <a href="#"  onClick={(event) => { event.preventDefault(); }}>
                    <img src="img/bod/Loudy Irwanto Ellias.png" style={{ height: '340px', width: '250px', boxShadow: '0 0 50px rgb(255 255 255 / 6%)' }} alt="Photo 1"/>
                    <h3 style={{ color: '#000', marginTop: '-5px' }}>Loudy Irwanto Ellias</h3>
                    <p style={{ fontFamily: 'Poppins', color: '#000' }}>President Commissioner</p>
                  </a>
                </div>
              </div>
              <div className="col-4 col-md-4 col-sm-12 hover-card">
                <div>
                  <a href="#"  onClick={(event) => { event.preventDefault();  }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <img src="img/bod/Iwan Hadiantoro.png" style={{ height: '340px', width: '250px', boxShadow: '0 0 50px rgb(255 255 255 / 6%)' }} alt="Photo 1" />
                    <h3 style={{ color: '#000', marginTop: '-5px' }}>Iwan Hadiantoro</h3>
                    <p style={{ fontFamily: 'Poppins', color: '#000' }}>Commissioner</p>
                  </a>
                </div>
              </div>
              <div className="col-4 col-md-4 col-sm-12 hover-card">
                <div>
                  <a href="#"  onClick={(event) => { event.preventDefault();  }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <img src="img/bod/Bayu Cahyono.png" style={{ height: '340px', width: '250px', boxShadow: '0 0 50px rgb(255 255 255 / 6%)' }} alt="Photo 1" />
                    <h3 style={{ color: '#000', marginTop: '-5px' }}>Bayu Cahyono</h3>
                    <p style={{ fontFamily: 'Poppins', color: '#000' }}>Commissioner</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: '100px' }}>
          <h2 style={{ fontWeight: 'normal', color: '#000', textAlign: 'center', position: 'relative'}}>Board of Directors</h2>
          <div style={{ borderBottom: '2px solid #C1272D', width: '150px', color: '#000', textAlign: 'center', margin: '0 auto', marginTop: '-20px' }}></div>
          <div className="container mt-10" style={{ marginBottom: '-35px', textAlign: 'center' }}>
            <div className="row">
              <div className="col-3 col-md-3 col-sm-12 hover-card">
                <div>
                  <a href="#"  onClick={(event) => { event.preventDefault();  }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <img src="img/bod/Etot Listyono.png" style={{ height: '340px', width: '250px', boxShadow: '0 0 50px rgb(255 255 255 / 6%)' }} alt="Photo 1" />
                    <h3 style={{ color: '#000', marginTop: '-5px' }}>Etot Listyono</h3>
                    <p style={{ fontFamily: 'Poppins', color: '#000' }}>President Director</p>
                  </a>
                </div>
              </div>
              <div className="col-3 col-md-3 col-sm-12 hover-card">
                <div>
                  <a href="#"  onClick={(event) => { event.preventDefault();  }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <img src="img/bod/Henry Wijaya.png" style={{ height: '340px', width: '250px', boxShadow: '0 0 50px rgb(255 255 255 / 6%)' }} alt="Photo 1" />
                    <h3 style={{ color: '#000', marginTop: '-5px' }}>Henry Wijaya</h3>
                    <p style={{ fontFamily: 'Poppins', color: '#000' }}>Director</p>
                  </a>
                </div>
              </div>
              <div className="col-3 col-md-3 col-sm-12 hover-card">
                <div>
                  <a href="#"  onClick={(event) => { event.preventDefault();  }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <img src="img/bod/Teguh Patmuryanto.png" style={{ height: '340px', width: '250px', boxShadow: '0 0 50px rgb(255 255 255 / 6%)' }} alt="Photo 1" />
                    <h3 style={{ color: '#000', marginTop: '-5px' }}>Teguh Patmuryanto</h3>
                    <p style={{ fontFamily: 'Poppins', color: '#000' }}>Director</p>
                  </a>
                </div>
              </div>
              <div className="col-3 col-md-3 col-sm-12 hover-card">
                <div>
                  <a href="#"  onClick={(event) => { event.preventDefault();  }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <img src="img/bod/David.png" style={{ height: '340px', width: '250px', boxShadow: '0 0 50px rgb(255 255 255 / 6%)' }} alt="Photo 1" />
                    <h3 style={{ color: '#000', marginTop: '-5px' }}>David</h3>
                    <p style={{ fontFamily: 'Poppins', color: '#000' }}>Director</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
