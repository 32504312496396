import React, {useEffect} from "react";
import { initFunction } from "../../main";

export const Subsidiaries = (props) => {

  const highlightStyle = {
    backgroundColor: 'black',
    color: 'white',
    textAlign: 'center'
  };
  
  useEffect(() => {
    initFunction();
  }, []);

  return (
    <div>
      <div className="slider-hero featured-carousel-2 owl-carousel">
        <div className="item">
          <div className="work">
            <div className="img d-flex align-items-center justify-content-center" style={{ backgroundImage: "url('img/intro-bg.jpg')" }}>
              <div className="container">
                <div className="text" style={{
                  position: 'relative',
                  marginTop: '40px',
                  color: '#FFF',
                  zIndex: 2
                }}>
                  <h2 style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>Subsidiaries</h2>
                  <h2 style={{ borderBottom: '5px solid #C1272D', width: '30%', marginTop : '-20px' }}></h2>
                  <p style={{ visibility: 'hidden' }}>To provide the best solution, from conceptual design into real product applied on site</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="containergray mb-10" style={{ backgroundColor: '#F2F2F2' }}>
        <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', boxShadow: '5px 5px 7px rgba(0,0,0,0.1)' }}>
          <div className="container mt-10">
            <h2 style={{ fontWeight: 'bold', color: '#000', borderLeft: '5px solid #C1272D', paddingLeft: '15px' }}>Subsidiaries</h2>
          </div>
        </div>
        <div className="container mt-10" style={{ marginBottom: '-35px' }}>
          <div className="mb-15">
            <h3 style={{ color: '#000' }}>PT Patria Maritime Lines</h3>
            <p style={{ fontFamily: 'Poppins', color: '#000' }}>Considering the potential business opportunity in coal and other energy transportation industy, PT United Tractors Pandu Engineering (UTE) established PT Patria Maritime Lines in 2008. We understand every customer is unique. Therefore, PML provides solutions in energy logistic, according to customers spesific needs to increase their productivity.</p>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <img style={{ width: "100%", boxShadow: '0 0 0 0 transparent', background: 'rgba(0,0,0,0)' }} src="img/affiliate_company/pml.png" className="img-responsive" alt="" />
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="row">
                  <div className="col-md-6 col-sm-12" style={{ width: '29%' }}>
                    <h4 style={highlightStyle}>VISION</h4>
                  </div>
                  <div className="col-md-6 col-sm-12" style={{width: '71%'}}>
                    <p style={{ fontFamily: 'Poppins', color: '#000', margin: 0 }}>To be an excellent integrated Maritime Logistic & Services Solution company in the nation</p>
                  </div>
                </div>
                <div className="row mt-15">
                  <div className="col-md-6 col-sm-12" style={{ width: '29%' }}>
                    <h4 style={highlightStyle}>MISSION</h4>
                  </div>
                  <div className="col-md-6 col-sm-12" style={{width: '71%'}}>
                    <ul style={{listStyleType: 'disc', fontFamily: 'Poppins', color: '#000'}}>
                      <li style={{marginLeft: '15px', marginBottom: '10px' }}>Prosper with the nation</li>
                      <li style={{marginLeft: '15px', marginBottom: '10px' }}>Provide operating excellence and best services to our stakeholders</li>
                      <li style={{marginLeft: '15px', marginBottom: '10px' }}>Create a place for Patrian to grow and give the best performance</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <h2 style={{ borderBottom: '3px solid black', width: '50%'}}></h2>
          </div>
          <div className="mb-15">
            <h3 style={{ color: '#000' }}>PT Patria Maritime Perkasa</h3>
            <p style={{ fontFamily: 'Poppins', color: '#000' }}>PT Patria Maritime Perkasa (PMP) was established in Batam in 2005. As a subsidiary of PT United Tractors Pandu Engineering who always give solution for stakeholders productivity, Perkasa Melati also have a commitment to provides solution in ship design & ship building industry and become an excelent solution partner for every stakeholders.</p>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <img style={{ width: "100%", boxShadow: '0 0 0 0 transparent', background: 'rgba(0,0,0,0)' }} src="img/affiliate_company/pmp.png" className="img-responsive" alt="" />
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="row">
                  <div className="col-md-6 col-sm-12" style={{ width: '29%' }}>
                    <h4 style={highlightStyle}>VISION</h4>
                  </div>
                  <div className="col-md-6 col-sm-12" style={{width: '71%'}}>
                    <p style={{ fontFamily: 'Poppins', color: '#000', margin: 0 }}>To be outstanding Indonesian Shipyard in maritime industry</p>
                  </div>
                </div>
                <div className="row mt-15">
                  <div className="col-md-6 col-sm-12" style={{ width: '29%' }}>
                    <h4 style={highlightStyle}>MISSION</h4>
                  </div>
                  <div className="col-md-6 col-sm-12" style={{width: '71%'}}>
                    <ul style={{listStyleType: 'disc', fontFamily: 'Poppins', color: '#000'}}>
                      <li style={{marginLeft: '15px', marginBottom: '10px' }}>Deliver total maritime engineering solution for our costumers</li>
                      <li style={{marginLeft: '15px', marginBottom: '10px' }}>Provide benefits and added value for stakeholder</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <h2 style={{ borderBottom: '3px solid black', width: '50%', marginTop: '30px'}}></h2>
          </div>
          <div className="mb-15">
            <h3 style={{ color: '#000' }}>PT Triatra Sinergia Pratama</h3>
            <p style={{ fontFamily: 'Poppins', color: '#000' }}>Triatra Sinergia Pratama is a subsidiary of PATRIA and a member of Astra, with a mission to deliver the best service and engineering solutions to create sustainable added value and to maximise customer productivity. Our business line revolves around domestic and international distribution of PATRIA and ULTRA products, ranging from units to parts, components, and services.</p>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <img style={{ width: "100%", boxShadow: '0 0 0 0 transparent', background: 'rgba(0,0,0,0)' }} src="img/affiliate_company/tsp.png" className="img-responsive" alt="" />
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="row">
                  <div className="col-md-6 col-sm-12" style={{ width: '29%' }}>
                    <h4 style={highlightStyle}>VISION</h4>
                  </div>
                  <div className="col-md-6 col-sm-12" style={{width: '71%'}}>
                    <p style={{ fontFamily: 'Poppins', color: '#000', margin: 0 }}>To be a leading total services solution & engineering-driven company in heavy industry</p>
                  </div>
                </div>
                <div className="row mt-15">
                  <div className="col-md-6 col-sm-12" style={{ width: '29%' }}>
                    <h4 style={highlightStyle}>MISSION</h4>
                  </div>
                  <div className="col-md-6 col-sm-12" style={{width: '71%'}}>
                    <ul style={{listStyleType: 'disc', fontFamily: 'Poppins', color: '#000'}}>
                      <li style={{marginLeft: '15px', marginBottom: '10px' }}>Prosper with the nation</li>
                      <li style={{marginLeft: '15px', marginBottom: '10px' }}>Deliver the best service and engineering solution for creating sustainable value added and maximizing customer productivity</li>
                      <li style={{marginLeft: '15px', marginBottom: '10px' }}>Create a place for Patrian to grow and give the best performance</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
