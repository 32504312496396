import React, {useEffect} from "react";
import { useNavigate,Link, useLocation } from "react-router-dom";
import { initFunction } from "../../main";

export const Facilities = (props) => {

  const navigate = useNavigate();

  useEffect(() => {
    initFunction();
  }, []);
  
  return (
    <div style={{ backgroundColor: '#F2F2F2' }}>
      <div className="slider-hero featured-carousel-2 owl-carousel">
        <div className="item">
          <div className="work">
            <div className="img d-flex align-items-center justify-content-center" style={{ backgroundImage: "url('img/intro-bg.jpg')" }}>
              <div className="container">
                <div className="text" style={{
                  position: 'relative',
                  marginTop: '40px',
                  color: '#FFF',
                  zIndex: 2
                }}>
                  <h2 style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>Facilities</h2>
                  <h2 style={{ borderBottom: '5px solid #C1272D', width: '30%', marginTop : '-20px' }}></h2>
                  <p style={{ visibility: 'hidden' }}>To provide the best solution, from conceptual design into real product applied on site</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="bod" className="containergray mb-15" style={{ backgroundColor: '#F2F2F2' }}>
        <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', boxShadow: '5px 5px 7px rgba(0,0,0,0.1)' }}>
          <div className="container mt-10">
            <h2 style={{ fontWeight: 'bold', color: '#000', borderLeft: '5px solid #C1272D', paddingLeft: '15px' }}>Facilities</h2>
          </div>
        </div>
        <div className="container mt-5" style={{ marginBottom: '-60px' }}>
          <div className="image-container mt-15 mb-15">
            <a className="image-wrapper" href="#"  onClick={(event) => { event.preventDefault(); window.scrollTo({ top: 0, behavior: 'smooth' }); navigate('/eng-facilities'); }}>
              <img src="img/facilities/eng_facilities.png" alt="Photo 1" />
              <div className="image-caption"><h2 style={{ fontWeight: 'bold', color: '#fff', borderBottom: '5px solid #C1272D', fontSize: '25px', textAlign: 'left' }}>Engineering Facilities</h2></div>
            </a>
            <a className="image-wrapper" href="#"  onClick={(event) => { event.preventDefault(); window.scrollTo({ top: 0, behavior: 'smooth' }); navigate('/manf-facilities'); }}>
              <img src="img/facilities/manf_facilities.png" alt="Photo 2" />
              <div className="image-caption"><h2 style={{ fontWeight: 'bold', color: '#fff', borderBottom: '5px solid #C1272D', fontSize: '25px', textAlign: 'left' }}>Manufacturing Facilities</h2></div>
            </a>
            <a className="image-wrapper" href="#" onClick={(event) => { event.preventDefault(); window.scrollTo({ top: 0, behavior: 'smooth' }); navigate('/reman-facilities'); }}>
              <img src="img/facilities/rem_facilities.png" alt="Photo 3" />
              <div className="image-caption"><h2 style={{ fontWeight: 'bold', color: '#fff', borderBottom: '5px solid #C1272D', fontSize: '25px', textAlign: 'left' }}>Remanufacturing Facilities</h2></div>
            </a>
            <a className="image-wrapper" href="#"  onClick={(event) => { event.preventDefault(); window.scrollTo({ top: 0, behavior: 'smooth' }); navigate('/pdc-facilities'); }}>
              <img src="img/facilities/pdc_facilities.png" alt="Photo 4" />
              <div className="image-caption"><h2 style={{ fontWeight: 'bold', color: '#fff', borderBottom: '5px solid #C1272D', fontSize: '25px', textAlign: 'left' }}>Patria Development Center (PDC) Facilities</h2></div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
