import React, { useState, useEffect,Component } from "react";
import Home from "./pages/home";
import Career from "./pages/career";
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Overview from "./pages/company-profile/overview-page";
import History from "./pages/company-profile/history-page";
import BOD from "./pages/company-profile/bod-page";
import BODDetail from "./pages/company-profile/bod-detail-page";
import Facilities from "./pages/company-profile/facilities-page";
import Subsidiaries  from "./pages/company-profile/subsudiaries-page";
import EngineeringFacilities  from "./pages/company-profile/eng-facilities-page";
import PDCFacilities  from "./pages/company-profile/pdc-facilities-page";
import ManfFacilities  from "./pages/company-profile/manf-facilities-page";
import RemanFacilities  from "./pages/company-profile/reman-facilities-page";
import Vcard  from "./pages/vcard";
import News  from "./pages/news";
import NewsHome  from "./pages/news-home";
import AfterSales from "./pages/after-sales/after-sales-page";
import Product from "./pages/product/product-page";
import ContactPage from "./pages/contact";

class App extends Component {
  render() {
    return (
      <BrowserRouter basename='/'>
        <Routes>
          <Route path='/' element={<Home/>}></Route>
          <Route path='/career' element={<Career/>}></Route>
          <Route path='/overview' element={<Overview/>}></Route>
          <Route path='/history' element={<History/>}></Route>
          <Route path='/bod' element={<BOD/>}></Route>
          <Route path='/bod-detail' element={<BODDetail/>}></Route>
          <Route path='/facilities' element={<Facilities/>}></Route>
          <Route path='/subsidiaries' element={<Subsidiaries/>}></Route>
          <Route path='/eng-facilities' element={<EngineeringFacilities/>}></Route>
          <Route path='/pdc-facilities' element={<PDCFacilities/>}></Route>
          <Route path='/manf-facilities' element={<ManfFacilities/>}></Route>
          <Route path='/reman-facilities' element={<RemanFacilities/>}></Route>
          <Route path='/vcard-detail' element={<Vcard />} />
          <Route path='/news-home' element={<NewsHome />} />
          <Route path='/news' element={<News />} />
          <Route path='/after-sales' element={<AfterSales />} />
          <Route path='/product' element={<Product />} />
          <Route path='/contact' element={<ContactPage />} />
        </Routes>
      </BrowserRouter>
    )
  }
}

export default App;
