import React, {useEffect} from "react";
import { initFunction } from "../../main";

export const BodDetail = (props) => {

  useEffect(() => {
    initFunction();
  }, []);
  
  return (
    <div>
      <div className="slider-hero featured-carousel-2 owl-carousel">
        <div className="item">
          <div className="work">
            <div className="img d-flex align-items-center justify-content-center" style={{ backgroundImage: "url('img/intro-bg.jpg')" }}>
              <div className="container">
                <div className="text" style={{
                  position: 'relative',
                  marginTop: '40px',
                  color: '#FFF',
                  zIndex: 2
                }}>
                  <h2 style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>Dewan Komisaris</h2>
                  <h2 style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>& Direksi</h2>
                  <h2 style={{ borderBottom: '5px solid #C1272D', width: '30%', marginTop : '-30px' }}></h2>
                  <p style={{ visibility: 'hidden' }}>To provide the best solution, from conceptual design into real product applied on site</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="containergray mb-10" style={{ backgroundColor: '#F2F2F2' }}>
        <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', boxShadow: '5px 5px 7px rgba(0,0,0,0.1)' }}>
          <div className="container mt-10">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="text">
                  <img src="img/bod/Arinto Danardono.png" style={{ height: '500px', width: 'auto', boxShadow: '0 0 50px rgb(255 255 255 / 6%)' }} alt="Photo 1" />
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div style={{ borderLeft: '5px solid #C1272D', paddingLeft: '15px' }}>
                  <h2 style={{ fontWeight: 'bold', color: '#000', marginBottom: '0' }}>Nama</h2>
                  <h3 style={{ color: '#000', marginTop: '0' }}>Jabatan Perusahaan</h3>
                </div>
                <p style={{ fontFamily: 'Poppins', color: '#000' }}>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.
                    Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container" style={{ marginTop: '80px', marginBottom: '-30px' }}>
          <div className="bod slider" style={{ height: '400px' }}>
            <a href="#" onClick={(event) => { event.preventDefault(); }}><img src="img/bod/Arinto Danardono.png" style={{ height: '350px', width: '300px', boxShadow: '0 0 50px rgb(255 255 255 / 6%)' }} alt="Photo 1" /></a>
            <a href="#" onClick={(event) => { event.preventDefault(); }}><img src="img/bod/Arinto Danardono.png" style={{ height: '350px', width: '300px', boxShadow: '0 0 50px rgb(255 255 255 / 6%)' }} alt="Photo 2" /></a>
            <a href="#" onClick={(event) => { event.preventDefault(); }}><img src="img/bod/Arinto Danardono.png" style={{ height: '350px', width: '300px', boxShadow: '0 0 50px rgb(255 255 255 / 6%)' }} alt="Photo 3" /></a>
            <a href="#" onClick={(event) => { event.preventDefault(); }}><img src="img/bod/Arinto Danardono.png" style={{ height: '350px', width: '300px', boxShadow: '0 0 50px rgb(255 255 255 / 6%)' }} alt="Photo 4" /></a>
            <a href="#" onClick={(event) => { event.preventDefault(); }}><img src="img/bod/Arinto Danardono.png" style={{ height: '350px', width: '300px', boxShadow: '0 0 50px rgb(255 255 255 / 6%)' }} alt="Photo 5" /></a>
            <a href="#" onClick={(event) => { event.preventDefault(); }}><img src="img/bod/Arinto Danardono.png" style={{ height: '350px', width: '300px', boxShadow: '0 0 50px rgb(255 255 255 / 6%)' }} alt="Photo 6" /></a>
          </div>
        </div>
      </div>
    </div>
  );
};
