import React, { useState, useEffect } from "react";
import JsonDataEng from "../data/data-eng.json";
import JsonDataIdn from "../data/data-idn.json";
import SmoothScroll from "smooth-scroll";
import "./../App.css";
import { NavBar } from "./../components/layout/navbar";
import { Navigation } from "./../components/layout/navigation";
import { Contact } from "./../components/layout/contact";
import { initFunction } from "../main";
import { useLocation, useNavigate } from "react-router-dom";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Career = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { Id } = location.state || {};
  var sessionLanguage = "";
  if(sessionStorage.getItem('language') == undefined){
    sessionLanguage = "ENG";
    sessionStorage.setItem('language', 'ENG');
  }else{
    sessionLanguage = sessionStorage.getItem('language');
    sessionStorage.setItem('language', sessionStorage.getItem('language'));
  }
  
  const [language, setLanguage] = useState(sessionLanguage);
  const handleClick = (language) => {
    setLanguage(language);
    sessionStorage.setItem('language', language);
  }

  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    if (language === "IDN") {
      setLandingPageData(JsonDataIdn);
    } else {
      setLandingPageData(JsonDataEng);
    }
  }, [language, Id]);  

  useEffect(() => {
    initFunction();
  }, []);

  const [expandedCardIndex, setExpandedCardIndex] = useState(null);

  const handleCardClick = (index) => {
    if (expandedCardIndex === index) {
      setExpandedCardIndex(null);
    } else {
      setExpandedCardIndex(index);
      // Scroll ke paling atas div card yang diklik
      const cardElement = document.getElementById(`card-content-${index}`);
      if (cardElement) {
        cardElement.scrollIntoView()
      }
    }
  };  

  //untuk memanggil data career dari api 
  const [dataCareer, setDataCareer] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://satria-apps.patria.co.id/satria-api-man/public/api/website-career-list?language=' + language, {
          method: 'GET',
          headers: {
            'Authorization': '527|8MeS4tgdX837vT9MJYHnLV8pAwN3Dqh1TCK7bTWT'
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        
        const dataArray = jsonData.data;
        setDataCareer(dataArray);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [language]);
  return (
    <div>
      <NavBar handleClick={handleClick}/>
      <Navigation data1={landingPageData.Navigation} data2={landingPageData.SubNavigation} data3={landingPageData.SubDropdown} data4={landingPageData.SubNavigationProduct}/>
      <div className="slider-hero featured-carousel-2 owl-carousel">
        <div className="item">
          <div className="work">
            <div className="img d-flex align-items-center justify-content-center" style={{ backgroundImage: "url('img/intro-bg.jpg')" }}>
              <div className="container">
                <div className="text" style={{
                  position: 'relative',
                  marginTop: '40px',
                  color: '#FFF',
                  zIndex: 2
                }}>
                  <h2 style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>Career</h2>
                  <h2 style={{ borderBottom: '5px solid #C1272D', width: '30%', marginTop : '-20px' }}></h2>
                  <p style={{ visibility: 'hidden' }}>To provide the best solution, from conceptual design into real product applied on site</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="containergray mb-15" style={{ backgroundColor: '#F2F2F2' }}>
        <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', boxShadow: '5px 5px 7px rgba(0,0,0,0.1)' }}>
          <div className="container mt-10">
            <h2 style={{ fontWeight: 'bold', color: '#000', borderLeft: '5px solid #C1272D', paddingLeft: '15px' }}>Career</h2>
          </div>
        </div>
        <div className="container mt-15" style={{ marginBottom: '-60px' }}>
          <div className="row mb-15">
          {dataCareer ? (
            dataCareer.map((career, index) => (
              <div key={index} className="col-md-6 col-sm-12 mb-10">
                <div className="card card-career" style={{ height: expandedCardIndex === index ? "auto" : "250px", maxHeight: '500px', backgroundColor: '#fff', border: 'white 3px solid', paddingLeft: '40px', paddingRight: '40px', transition: 'all 0.5s ease' }}>
                  <div className="card-body" id={`card-content-${index}`} style={{ overflowY: expandedCardIndex === index ? "auto" : "hidden" }}>
                    <h4 style={{ fontWeight: 'bold', color: '#000' }}>{career.position}</h4>
                    <h4 style={{ fontWeight: 'normal', color: '#C1272D', fontSize: '20' }}>{career.code}</h4><br/>
                    <div style={{ height: expandedCardIndex === index ? "auto" : "120px", overflow: 'hidden', transition: 'all 0.5s ease'}}>
                      <h4 style={{ fontWeight: 'normal', color: '#000', fontSize: '17'}}>Job Description</h4>
                      <ul style={{ listStyleType: 'disc', paddingLeft: '20px', opacity: expandedCardIndex === index ? "1" : "0.2" }}>
                        {career.job_description.map((description, i) => (
                          <li key={i} style={{ marginBottom: '10px' }}>{description.job_description}</li>
                        ))}
                      </ul><br/>
                      <h4 style={{ fontWeight: 'normal', color: '#000', fontSize: '17' }}>Requirement</h4>
                      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        {career.job_requirements.map((requirement, i) => (
                          <li key={i} style={{ marginBottom: '10px' }}>{requirement.requirements}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div style={{ position: 'relative', zIndex: '999', display: 'flex', justifyContent: 'space-between', paddingLeft: '15px', paddingRight: '15px', marginTop: expandedCardIndex === index ? "0px" : "-100px"}}>
                    <a className="tag-a-career" style={{ display: 'flex', alignItems: 'center' }} href="#" onClick={(event) => { event.preventDefault(); handleCardClick(index); }}>
                      <h4 style={{ fontWeight: 'bold', color: '#C1272D', fontSize: '15' }}>{'Read more'}</h4>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.34 13.26" style={{ marginLeft: '10px', width: '15px', height: '15px', transform: expandedCardIndex === index ? 'rotate(180deg)' : 'none', transition: 'all 0.5s ease' }}>
                        <polyline fill="none" stroke="#c1272d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.48px" points="1.24 1.24 10.17 12.02 19.1 1.24" />
                      </svg>
                    </a>
                    <a type="button" className="btn btn-danger" style={{ fontFamily: 'Poppins', width: '40%', height: '30px', paddingTop: '2'}} href={career.link} target="_blank">Apply</a>
                  </div>
                </div>
              </div>
            ))
          ) : "loading"}
          </div>
        </div>
      </div>
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default Career;
