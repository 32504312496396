import React, {useEffect} from "react";
import { initFunction } from "../../main";

export const EngineeringFacilities = (props) => {

  useEffect(() => {
    initFunction();
  }, []);
  
  return (
    <div>
      <div className="slider-hero featured-carousel-2 owl-carousel">
        <div className="item">
          <div className="work">
            <div className="img d-flex align-items-center justify-content-center" style={{ backgroundImage: "url('img/intro-bg.jpg')" }}>
              <div className="container">
                <div className="text" style={{
                  position: 'relative',
                  marginTop: '40px',
                  color: '#FFF',
                  zIndex: 2
                }}>
                  <h2 style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>Engineering Facilities</h2>
                  <h2 style={{ borderBottom: '5px solid #C1272D', width: '30%', marginTop : '-10px' }}></h2>
                  <p style={{ visibility: 'hidden' }}>To provide the best solution, from conceptual design into real product applied on site</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="containergray mb-15" style={{ backgroundColor: '#F2F2F2', marginTop: '-60px'}}>
        <div className="container mt-15" style={{ marginBottom: '-60px' }}>
          <div className="card mt-15 mb-15" style={{ backgroundColor: '#1A1A1A'}}>
            <div className="row mt-8">
              <div className="col-md-6 col-sm-12">
                <img style={{ width: "100%", boxShadow: '0 0 0 0 transparent', background: 'rgba(0,0,0,0)' }} src="img/engineeringfacilities.png" className="img-responsive" alt="" />
                <h3 className="eng-facilities-element-text" style={{ backgroundColor: '#212121', color: 'white', textAlign: 'left', paddingLeft: '30px', paddingRight: '30px', border: '5px solid #212121', display: 'inline-block', marginBottom: '20px', fontSize: '30', fontWeight: 'bold', borderStartStartRadius: '10px' , borderStartEndRadius: '10px', borderEndStartRadius: '10px', borderEndEndRadius: '10px', marginTop: '-40px' }}>Engineering Capabilities</h3>
              </div>
              <div className="col-md-6 col-sm-12 eng-facilities-element-2">
                <div style={{ marginTop: '-20px', marginLeft:'-30px' }}>
                  <h3 style={{ border: '25px solid #212121', width: '100%'}}></h3>
                  <h3 style={{ color: 'white', textAlign: 'left', fontSize: '30', fontWeight: 'bold', marginTop: '-50px', marginLeft: '20px' }}>Cutting Edge Software</h3>
                  <div style={{ backgroundImage: "url('img/engineeringfacilities2.png')"}}>
                    <ul className="eng-facilities-element-ul" style={{listStyleType: 'disc', fontFamily: 'Poppins', color: '#fff', fontSize: '20px', marginLeft: '40px', paddingTop: '20px', paddingBottom: '13px'}}>
                      <li style={{marginLeft: '15px', marginBottom: '10px', color: '#fff' }}>Solidworks (3D, Electrical 3D, FEA Simulation, Composer, Inspection)</li>
                      <li style={{marginLeft: '15px', marginBottom: '10px', color: '#fff' }}>NASTRAN</li>
                      <li style={{marginLeft: '15px', marginBottom: '10px', color: '#fff' }}>MATLAB</li>
                      <li style={{marginLeft: '15px', marginBottom: '10px', color: '#fff' }}>Automation Studio</li>
                      <li style={{marginLeft: '15px', marginBottom: '10px', color: '#fff' }}>Autodesk Product Design (AutoCAD 2020, 3dsMax, Showcase)</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginLeft: '30px'}}>
              <div className="col-md-3 col-sm-6 mb-10 eng-facilities-element-ul-2" style={{ borderRight: '1px solid white', paddingLeft: '30px', height: '200px' }}>
                <h3 style={{ fontFamily: 'Poppins', color: '#FFFFFF', fontSize: '16', fontWeight: 'bold', marginBottom: '20px', height: '40px' }}>Product Development Engineering</h3>
                <ul style={{listStyleType: 'disc', fontFamily: 'Poppins', color: '#fff'}}>
                  <li style={{marginLeft: '15px', marginBottom: '10px', color: '#fff' }}>Research & Development Engineer</li>
                  <li style={{marginLeft: '15px', marginBottom: '10px', color: '#fff' }}>Application Engineer</li>
                  <li style={{marginLeft: '15px', marginBottom: '10px', color: '#fff' }}>Design Engineer</li>
                </ul>
              </div>
              <div className="col-md-3 col-sm-6 mb-10 eng-facilities-element-ul-2" style={{ borderRight: '1px solid white', paddingLeft: '30px', height: '200px' }}>
                <h3 style={{ fontFamily: 'Poppins', color: '#FFFFFF', fontSize: '16', fontWeight: 'bold', marginBottom: '20px', height: '40px' }}>Prototyping Workshop</h3>
                <ul style={{listStyleType: 'disc', fontFamily: 'Poppins', color: '#fff'}}>
                  <li style={{marginLeft: '15px', marginBottom: '10px', color: '#fff' }}>Product Prototyping</li>
                  <li style={{marginLeft: '15px', marginBottom: '10px', color: '#fff' }}>Product & System Testing</li>
                  <li style={{marginLeft: '15px', marginBottom: '10px', color: '#fff' }}>3D Printing</li>
                </ul>
              </div>
              <div className="col-md-6 col-sm-12 mb-10 eng-facilities-element-ul-2" style={{ paddingLeft: '30px' }}>
                <h3 style={{ fontFamily: 'Poppins', color: '#FFFFFF', fontSize: '16', fontWeight: 'bold', marginBottom: '20px', height: '40px' }}>Prototyping Workshop</h3>
                <ul style={{listStyleType: 'disc', fontFamily: 'Poppins', color: '#fff'}}>
                  <div className="row">
                    <div className="col-md-6">
                      <li style={{marginLeft: '15px', marginBottom: '10px', color: '#fff' }}>Material Preparation</li>
                      <li style={{marginLeft: '15px', marginBottom: '10px', color: '#fff' }}>Welding</li>
                      <li style={{marginLeft: '15px', marginBottom: '10px', color: '#fff' }}>Assembling</li>
                      <li style={{marginLeft: '15px', marginBottom: '10px', color: '#fff' }}>Painting</li>
                    </div>
                    <div className="col-md-6">
                      <li style={{marginLeft: '15px', marginBottom: '10px', color: '#fff' }}>Electric & Automation</li>
                      <li style={{marginLeft: '15px', marginBottom: '10px', color: '#fff' }}>Hydraulic & Pneumatic</li>
                      <li style={{marginLeft: '15px', marginBottom: '10px', color: '#fff' }}>Industrial</li>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
            <div className="eng-facilities-element-3 d-none" style={{ marginBottom: '-20px'}}>
              <div style={{ marginTop: '-20px', marginLeft:'-1px' }}>
                <h3 style={{ border: '25px solid #212121', width: '100%'}}></h3>
                <h3 className="eng-facilities-element-text-2" style={{ color: 'white', textAlign: 'left', fontSize: '30', fontWeight: 'bold', marginTop: '-50px', marginLeft: '20px', marginBottom: '-20px' }}>Cutting Edge Software</h3>
                <div style={{ backgroundImage: "url('img/engineeringfacilities2.png')", borderEndStartRadius: '10px', borderEndEndRadius: '10px' }}>
                  <ul style={{listStyleType: 'disc', fontFamily: 'Poppins', color: '#fff', fontSize: '20px', marginLeft: '40px', marginTop: '30px', marginBottom: '20px', paddingTop: '20px', paddingBottom: '15px'}}>
                    <li style={{marginLeft: '15px', marginBottom: '10px', color: '#fff' }}>Solidworks (3D, Electrical 3D, FEA Simulation, Composer, Inspection)</li>
                    <li style={{marginLeft: '15px', marginBottom: '10px', color: '#fff' }}>NASTRAN</li>
                    <li style={{marginLeft: '15px', marginBottom: '10px', color: '#fff' }}>MATLAB</li>
                    <li style={{marginLeft: '15px', marginBottom: '10px', color: '#fff' }}>Automation Studio</li>
                    <li style={{marginLeft: '15px', marginBottom: '10px', color: '#fff' }}>Autodesk Product Design (AutoCAD 2020, 3dsMax, Showcase)</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
