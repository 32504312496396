import React, { useState, useEffect } from "react";
import { Navigation } from "../components/layout/navigation";
import JsonDataEng from "../data/data-eng.json";
import JsonDataIdn from "../data/data-idn.json";
import SmoothScroll from "smooth-scroll";
import "../App.css";
import { NavBar } from "../components/layout/navbar";
import { initFunction } from "../main";
import { Contact } from "../components/layout/contact";
import { useLocation } from "react-router-dom";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const ContactPage = () => {
  const location = useLocation();
  const { Id } = location.state || {};
  var sessionLanguage = "";
  if(sessionStorage.getItem('language') == undefined){
    sessionLanguage = "ENG";
    sessionStorage.setItem('language', 'ENG');
  }else{
    sessionLanguage = sessionStorage.getItem('language');
    sessionStorage.setItem('language', sessionStorage.getItem('language'));
  }
  
  const [language, setLanguage] = useState(sessionLanguage);
  const handleClick = (language) => {
    setLanguage(language);
    sessionStorage.setItem('language', language);
  }

  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    if (language === "IDN") {
      setLandingPageData(JsonDataIdn);
    } else {
      setLandingPageData(JsonDataEng);
    }
  }, [language, Id]);  

  useEffect(() => {
    initFunction();
  }, []);

  return (
    <div>
      <NavBar handleClick={handleClick}/>
      <Navigation data1={landingPageData.Navigation} data2={landingPageData.SubNavigation} data3={landingPageData.SubDropdown} data4={landingPageData.SubNavigationProduct}/>
      <div className="slider-hero featured-carousel-2 owl-carousel">
        <div className="item">
          <div className="work">
            <div className="img d-flex align-items-center justify-content-center" style={{ backgroundImage: "url('img/intro-bg.jpg')" }}>
              <div className="container">
                <div className="text" style={{
                  position: 'relative',
                  marginTop: '40px',
                  color: '#FFF',
                  zIndex: 2
                }}>
                  <h2 style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>Contact</h2>
                  <h2 style={{ borderBottom: '5px solid #C1272D', width: '30%', marginTop : '-20px' }}></h2>
                  <p style={{ visibility: 'hidden' }}>To provide the best solution, from conceptual design into real product applied on site</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="containergray mb-15" style={{ backgroundColor: '#F2F2F2' }}>
        <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', boxShadow: '5px 5px 7px rgba(0,0,0,0.1)' }}>
          <div className="container mt-10">
            <h2 style={{ fontWeight: 'bold', color: '#000', borderLeft: '5px solid #C1272D', paddingLeft: '15px' }}>Contact</h2>
          </div>
        </div>
        <div className="container mt-15" style={{ marginBottom: '-60px' }}>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <img style={{ width: "100%", boxShadow: '0 0 0 0 transparent', background: 'rgba(0,0,0,0)' }} src="img/contact.png" className="img-responsive" alt="" />
            </div>
            <div className="col-md-6 col-sm-12">
              <li style={{ marginBottom: '20px', fontFamily: 'Poppins', color: '#C1272D', fontSize: '20', listStyleType: 'square' }}>Informasi Kontak</li>
              <ul style={{fontFamily: 'Poppins', color: '#000'}}>
                <li style={{marginLeft: '27px', marginBottom: '10px' }}>PT United Tractors Pandu Engineering</li>
                <li style={{marginLeft: '27px', marginBottom: '10px' }}>Jl. Jababeka XI Blok H 30 - 40 Kawasan Industri Jababeka Cikarang, 17530 Indonesia</li><br/>
                <li style={{marginLeft: '27px', marginBottom: '10px' }}><strong>Corporate Communication</strong></li>
                <li style={{marginLeft: '27px', marginBottom: '10px' }}>Email : corcom@patria.co.id</li>
                <li style={{marginLeft: '27px', marginBottom: '10px' }}>Phone : (+62) 21 893 5016</li><br/>
                <li style={{marginLeft: '27px', marginBottom: '10px' }}><strong>Trading Information</strong></li>
                <li style={{marginLeft: '27px', marginBottom: '10px' }}><strong>PT Triatra Sinergia Pratama (TRIATRA)​</strong></li>
                <li style={{marginLeft: '27px', marginBottom: '10px' }}>Jl. Raya Bekasi No.KM 22, Cakung Barat, Kec. Cakung, Kota Jakarta Timur, Daerah Khusus Ibukota Jakarta 13910</li>
                <li style={{marginLeft: '27px', marginBottom: '10px' }}>Website : <a className="nav-link" href="https://www.triatra.co.id" role="button" data-bs-toggle="dropdown" aria-expanded="false" target="_blank">www.triatra.co.id</a></li>
              </ul>
            </div>
          </div>
          <div className="row mt-15 mb-15">
            <div className="col-md-12 col-sm-12">
              <h2 style={{ fontWeight: 'bold', color: '#000'}}>Plant Support</h2>
              <h2 style={{ borderBottom: '3px solid #C1272D', marginTop: '10px', width: '50%' }}></h2>
              <div className="row">
                <div className="col-md-3 col-sm-6 mb-10">
                  <ul style={{ marginBottom: '20px', fontFamily: 'Poppins', color: '#C1272D', fontSize: '20', listStyleType: 'square', marginLeft: '20px' }}>
                    <li><span style={{ fontFamily: 'Poppins', color: '#000', fontSize: '14' }}>RJKT (Plant Remanufacturing Jakarta)</span></li>
                  </ul>
                  <li style={{marginLeft: '20px', marginBottom: '10px', listStyleType: 'none', fontFamily: 'Poppins', color: '#000' }}>JL Raya Bekasi Km 22, Cakung Barat, Cakung, Kota Administrasi Jakarta Timur, DKI Jakarta 13910</li>
                </div>
                <div className="col-md-3 col-sm-6 mb-10">
                  <ul style={{ marginBottom: '20px', fontFamily: 'Poppins', color: '#C1272D', fontSize: '20', listStyleType: 'square', marginLeft: '20px' }}>
                    <li><span style={{ fontFamily: 'Poppins', color: '#000', fontSize: '14' }}>RBPS (Plant Remanufacturing Balikpapan Sudirman)</span></li>
                  </ul>
                  <li style={{marginLeft: '20px', marginBottom: '10px', listStyleType: 'none', fontFamily: 'Poppins', color: '#000' }}>Jl. Jend Sudirman No.844, Balikpapan Selatan 76114, Kalimantan Timur.</li>
                </div>
                <div className="col-md-3 col-sm-6 mb-10">
                  <ul style={{ marginBottom: '20px', fontFamily: 'Poppins', color: '#C1272D', fontSize: '20', listStyleType: 'square', marginLeft: '20px' }}>
                    <li><span style={{ fontFamily: 'Poppins', color: '#000', fontSize: '14' }}>RBPM (Plant Remanufacturing Balikpapan Mulawarman)</span></li>
                  </ul>
                  <li style={{marginLeft: '20px', marginBottom: '10px', listStyleType: 'none', fontFamily: 'Poppins', color: '#000' }}>Jl. Mulawarman No.22, Manggar Balikpapan, Kalimantan Timur 76116</li>
                </div>
                <div className="col-md-3 col-sm-6 mb-10">
                  <ul style={{ marginBottom: '20px', fontFamily: 'Poppins', color: '#C1272D', fontSize: '20', listStyleType: 'square', marginLeft: '20px' }}>
                    <li><span style={{ fontFamily: 'Poppins', color: '#000', fontSize: '14' }}>RTMK (Plant Remanufacturing Timika)</span></li>
                  </ul>
                  <li style={{marginLeft: '20px', marginBottom: '10px', listStyleType: 'none', fontFamily: 'Poppins', color: '#000' }}>Jl Kuala Tembaga E4 LIP, Kuala Kencana, Timika, Mimika, Papua 99920</li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default ContactPage;
