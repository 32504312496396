import React from "react";

export const Afco = (props) => {
  return (
    <div style={{ backgroundColor: '#F2F2F2', border: '1px solid #ccc', boxShadow: '5px 5px 7px rgba(0,0,0,0.1)'}}>
      <div className="container mb-10 mt-10">
        <div className="row">
          <div className="col-md-5">
            <div className="testimonial">
              <h3 className="text-center" style={{ color: '#C1272D' }}>AFFILIATE COMPANY</h3>
            </div>
          </div>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-2">
                  <div className="testimonial text-center">
                    <a href={d.url} target="_blank">
                      <img src={d.img} alt="" width="170" />{" "}
                    </a>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
