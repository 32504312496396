import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    emailjs
      .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", e.target, "YOUR_USER_ID")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div>
      <div style={{ backgroundColor: '#212121' }}>
        <div className="container">
          <div className="row mt-10 mb-10">
            <div className="col-md-12">
              <div className="contact-item">
              <strong style={{ color: '#fff' }}><h4>PT United Tractors Pandu Engineering</h4>  <br></br>
              Jl. Jababeka XI Blok H 30 - 40, <br></br>
              Kawasan Industri Jababeka,<br></br>
              Cikarang 17530 - Indonesia.</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: '#212121' }}>
        <div className="container">
          <div className="row mt-10 mb-10">
            <div className="col-xs-12 col-md-3 text-center-img-contact">
              <img src={"img/foot_image.png"} alt="..." width={'250'} className="team-img" />
            </div>
            <div className="col-xs-12 col-md-6 text-center mt-10">
              <div className="contact-item">
                <p style={{ color: '#fff' }}>
                  &copy; 2023 PT United Tractors Pandu Engineering all right reserved. 
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="col-md-12 col-md-offset-1 contact-info mt-3">
                <div className="social">
                  <ul>
                    <li>
                      <a target="_blank" href={props.data ? props.data.youtube : "/"}>
                        <i className="fa fa-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href={props.data ? props.data.facebook : "/"}>
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href={props.data ? props.data.linkedin : "/"}>
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href={props.data ? props.data.instagram : "/"}>
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
