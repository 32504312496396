import React from "react";
import { useNavigate,Link, useLocation, json } from "react-router-dom";

export const Header = (props) => {
  const navigate = useNavigate();
  var active = "item";
return (
<div className="slider-hero">
  <div className="featured-carousel owl-carousel">
    {props.data ? props.data.map((d, i) => (
      <div key={`${i}`} className="item">
        <div className="work">
          <div className="img d-flex align-items-center justify-content-center" style={{ backgroundImage: `url('${d.img}')` }}>
            <div className="container">
              <div className="text" style={{
                position: 'relative',
                marginTop: '40px',
                color: '#FFF',
                zIndex: 2
              }}>
                <h2 style={{ fontWeight: 'bold' }}>{d.title1}</h2>
                {d.title2 != "" ? (
                  <>
                    <h2 style={{ fontWeight: 'bold' }}>{d.title2}</h2>
                  </>
                ) : null}
                <h2 style={{ borderBottom: '5px solid #C1272D', width: '30%' }}></h2>
                <p style={{ fontFamily: 'Poppins', width: '52%' }}>{d.description}</p>
                <button type="button" className="btn btn-secondary" style={{ fontFamily: 'Poppins'}} onClick={(event) => { event.preventDefault(); navigate(d.href); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>{d.button}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )) : "loading"}
  </div>
  <div className="my-5 text-center">
    <ul className="carouselindicator">
    {props.data ? props.data.map((d, i) => (
      <li key={`${i}`} className={i === 0 ? "active img" : ""}>
        <a href="#"><img src={d.img} alt="Image" className="img-fluid" /></a>
      </li>
    )) : "loading"}
    </ul>
  </div>
</div>
);
};