import React from "react";

export const NavBar = ({ handleClick }) => {

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top" style={{ backgroundColor:'#212121 ',maxHeight: "30px" }}>
      <div style={{ marginTop: "-3px" }} className="container" id="translate">
        <div className="pull-right">
          <a href="#" onClick={(event) => { event.preventDefault(); handleClick("IDN"); }}>
            <img src={"img/ID.png"} alt="..." width={'25'} className="team-img" />
            IDN
          </a>
          <a href="#" onClick={(event) => { event.preventDefault(); handleClick("ENG"); }}>
            <img src={"img/GB.png"} alt="..." width={'25'} className="team-img" />
            ENG
          </a>
        </div>
      </div>
    </nav>
  );
};
