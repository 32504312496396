import React, {useEffect} from "react";
import { initFunction } from "../../main";

export const History = (props) => {

  useEffect(() => {
    initFunction();
  }, []);
  
  return (
    <div>
      <div className="slider-hero featured-carousel-2 owl-carousel">
        <div className="item">
          <div className="work">
            <div className="img d-flex align-items-center justify-content-center" style={{ backgroundImage: "url('img/intro-bg.jpg')" }}>
              <div className="container">
                <div className="text" style={{
                  position: 'relative',
                  marginTop: '40px',
                  color: '#FFF',
                  zIndex: 2
                }}>
                  <h2 style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>Company</h2>
                  <h2 style={{ fontWeight: 'bold', marginTop: '-20px', textTransform: 'capitalize' }}>History</h2>
                  <h2 style={{ borderBottom: '5px solid #C1272D', width: '30%', marginTop : '-10px' }}></h2>
                  <p style={{ visibility: 'hidden' }}>To provide the best solution, from conceptual design into real product applied on site</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="containergray mb-15">
        <div className="container" style={{ marginTop: "30px" }}>
          <div className="col-md-12  section-title">
            <h2 style={{ fontWeight: 'bold', color: '#000', borderLeft: '5px solid #C1272D', paddingLeft: '15px' }}>History</h2>
            <p style={{ fontFamily: 'Poppins', color: '#000' }}>PT United Tractors Pandu Engineering, one of Indonesia’s leading heavy equipment manufacturing & engineering companies.</p>
            <p style={{ fontFamily: 'Poppins', color: '#000' }}>PT United Tractors Pandu Engineering was established since 1983 with major share 99,9% by PT United Tractors Tbk which belong to PT Astra International Tbk. Our unique characteristic of customizing product based on client requirements has made us the partner of choice.</p>
            <p style={{ fontFamily: 'Poppins', color: '#000' }}>We have a commitment to provide the best solution. From conceptual design into real product applied on site. Complete with excellent after sales service support to maximize the productivity. For us, exceed our customer’s expectations and success are sure to follow.</p>
          </div>
          <img style={{ width: "100%", boxShadow: '0 0 0 0 transparent' }} src="img/history.png" className="img-responsive" alt="" />
        </div>
      </div>
    </div>
  );
};
